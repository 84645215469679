import React, { Fragment, useEffect, useState, useContext, useRef, forwardRef } from 'react'
import {
  useTransition,
  useSpring,
  useSprings,
  useChain,
  config,
  animated,
  useSpringRef,
  easings,
  useTrail,
  to,
  SpringValue
} from '@react-spring/web'
import backgrounds from './backgrounds'
import transitions from './transitions'
import customAnimations from './custom-animations'
import animationPresets from './presets'
import patterns from './patterns'
import extend from 'deep-extend';
import {
    extractAndScalePositionProps, getAssetSpringProps,
    setPositionCacheValue, getScaledPosition, prepareEasing,
    outerHeight, getAnimationScale, makeTo
 } from './utils'
 import styles from './slides.module.sass';


export const DEFAULT_SLIDE_WIDTH = 600
export const DEFAULT_SLIDE_HEIGHT = 337.5


function NextIcon({ fill }){
	return <svg viewBox="0 0 14 14" enableBackground="new 0 0 14 14" fill={fill}>
		<path d="M4.506,0l-1.67,1.67l5.413,5.413l-5.413,5.413l1.67,1.671l7.084-7.083L4.506,0z"/>
	</svg>
}


function FullScreenIcon(){
    return <svg height="24px" viewBox="0 0 24 24" width="24px">
        <path d="M0 0h24v24H0z" fill="none"/><path d="M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z"/>
    </svg>
}

function CloseIcon(){
	return <svg height="24" viewBox="0 0 24 24" width="24">
	    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
	    <path d="M0 0h24v24H0z" fill="none"/>
	</svg>
}


var interpolatedProps = [
    'transformTranslate3d', 'transformScale3d', 'transformRotate3d'
];


function each(list, callback){
    var i;
    for (i = 0; i < list.length; i++){
        if (callback(list[i], i) === false)
            break;
    }
}


function prepareSlateParams(parameters){
    var query = []

    var key
    for (key in parameters){
        if (parameters[key] instanceof Array){
            parameters[key].forEach(p => {
                query.push(`${key}=${encodeURIComponent(p)}`)
            })
        } else {
            query.push(`${key}=${encodeURIComponent(parameters[key])}`)
        }
    }

    return query.join('&')
}


export default function Slides(props){
    const content = props.content
    const slideshowWrapper = props.slideshowWrapper
    const parentSetCurrentSlideNo = props.setCurrentSlideNo
    const [slides, animations] = getAnimatedSlides(content)


    function getSlidesToRenderBasedOnCurrentSlide(startingPoint){
        // If there is a slide before, and then three slides later, if they are there.
        var lastSlide = slides.length - 1
        if (startingPoint){
            var lastSlideToRender = Math.min(startingPoint + 3, lastSlide), i,
                slideIndexes = [startingPoint - 1]

            for (i = 0; i <= (lastSlideToRender - startingPoint); i++){
                slideIndexes.push(startingPoint + i)
            }

            return slideIndexes
        } else {
            return [startingPoint, startingPoint + 1, startingPoint + 2, startingPoint + 3, startingPoint + 4]
        }
    }

    const [canMoveInEitherDirection, setCanMoveInEitherDirection] = useState(-1)
    const [scale, setScale] = useState([1, 'width'])
    const [slidesToRender, setSlidesToRender] = useState(
        props.currentSlideNo ? getSlidesToRenderBasedOnCurrentSlide(props.currentSlideNo.index): [0, 1, 2, 3, 4])
    const [changeAction, setChangeAction] = useState()

    // This is merely a tracker from the below component that renders it.
    // const [currentSlideNo, setCurrentSlideNo] = useState(props.currentSlideNo?.index || 0)
    const [currentSlideNo, setCurrentSlideNo] = useState(props.currentSlideNo && props.currentSlideNo.index || 0)
    // const currentSlideNoRef = useRef(currentSlideNo)

    var slidesWrapper = useRef(null),
        slideHeight = useRef()

    function fullscreenChanged(){
        if (!document.fullscreen && scale[0] > 1){
            setScale(getAnimationScale(
                slidesWrapper, true, DEFAULT_SLIDE_HEIGHT, DEFAULT_SLIDE_WIDTH))
        }
    }

    function documentResized(){
        setScale(getAnimationScale(
            slidesWrapper, true, DEFAULT_SLIDE_HEIGHT, DEFAULT_SLIDE_WIDTH))
    }



    useEffect(() => {
        setScale(getAnimationScale(
            slidesWrapper, true, DEFAULT_SLIDE_HEIGHT, DEFAULT_SLIDE_WIDTH))

        if (props.onMount){
            props.onMount()
        }

        slideHeight.current = outerHeight(slidesWrapper.current)

        prepareFonts()

        return () => {
            if (props.onUnmount){
                props.onUnmount()
            }
        }

    }, [])

    useEffect(() => {
        var slideshow = document.querySelector('.' + styles['Slideshow-slideshowAndActions-slideshow']);
        slideshow.addEventListener('fullscreenchange', fullscreenChanged, false)
        window.addEventListener('resize', documentResized, false)

        return () => {
            slideshow.removeEventListener('fullscreenchange', fullscreenChanged)
            window.removeEventListener('resize', documentResized)
        }
    }, [scale])

    var moveAnimationBackward = () => {
        setChangeAction({ action: 'backward', time: new Date() })
    }

    var moveAnimationForward = () => {
        setChangeAction({ action: 'forward', time: new Date() })
    }

    var onWindowKeydown = (event) => {
        if (event.key === 'ArrowRight'){
            moveAnimationForward()
        } else if (event.key === 'ArrowLeft'){
            moveAnimationBackward()
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', onWindowKeydown, false)

        return () => {
            window.removeEventListener('keydown', onWindowKeydown)
        }
    })

    useEffect(() => {
        // Lazily add more slides to render.

        // Do I have atleast one slide before me?
        var newSlidesToRender = slidesToRender.slice()
        if (currentSlideNo && slidesToRender.indexOf(currentSlideNo - 1) === -1){
            newSlidesToRender.push(currentSlideNo - 1)
        }

        // Are there more slides to even load.
        if (((slides.length - 1) >= (currentSlideNo + 3))
            // Have slides 3 steps later been loaded already.
            && slidesToRender.indexOf(currentSlideNo + 3) === -1){
            var maxSlide = Math.max(...slidesToRender),
                deltaFromTotalSlidesCount = slides.length - 1 - maxSlide

            // Are there atleast 3 slides left to go? If so, load 3, or else, how
            // many ever are left.
            Array(deltaFromTotalSlidesCount > 3 ? 3 : deltaFromTotalSlidesCount).fill(0).forEach((item, i) => {
                newSlidesToRender.push(maxSlide + i + 1)
            })
        }

        // Weak check to make sure they are different, since we only add upwards.
        if (newSlidesToRender.length !== slidesToRender.length){
            setSlidesToRender(newSlidesToRender.sort())
        }
    }, [currentSlideNo])

    useEffect(() => {
        if (props.currentSlideNo && props.currentSlideNo.index !== 0){
        // if (props.currentSlideNo?.index !== 0){
            setChangeAction({ action: 'set', value: props.currentSlideNo, time: new Date() })
        }

        // resetSlidesToRender()

        // if (currentSlideNoRef.current !== props.currentSlideNo){
        // }
        //
        // currentSlideNoRef.current = props.currentSlideNo
    }, [props.currentSlideNo])

    //<Tip content='Fullscreen'></Tip>

    return <div className='Slides'>
        <div className={styles['Slideshow-slideshowAndActions-slideshow']}>
            <AnimatedSlides
                ref={slidesWrapper}
                slides={slides.filter((slide, i) => slidesToRender.indexOf(i) !== -1)}
                animations={animations}
                scale={scale}
                changeAction={changeAction}
                slideshowWrapper={slideshowWrapper}
                setCurrentSlideNo={(no) => {
                    setCurrentSlideNo(no)

                    if (parentSetCurrentSlideNo){
                        parentSetCurrentSlideNo(no)
                    }
                }}
            />

            <div className={styles['Slideshow-slideshowAndActions-slideshow-controls']}>
                <div className={styles['Slideshow-slideshowAndActions-slideshow-controls-slides']}>
                    <div className={styles['Slideshow-slideshowAndActions-slideshow-controls-slides-backward']} onClick={moveAnimationBackward}>
                        <NextIcon />
                    </div>
                    <div className={styles['Slideshow-slideshowAndActions-slideshow-controls-slides-forward']} onClick={moveAnimationForward}>
                        <NextIcon />
                    </div>
                </div>
                <div className={styles['Slideshow-slideshowAndActions-slideshow-controls-screen']}>
                    <button onClick={() => {
                        var slideshow = document.querySelector('.Slideshow-slideshowAndActions-slideshow');

                        if (document.fullscreen){
                            setScale([1, 'width'])
                            document.exitFullscreen();

                        } else {
                            slideshow.requestFullscreen();
                            // setScale(screen.height / slideHeight.current)
                            setScale(getAnimationScale(
                                slidesWrapper, true, DEFAULT_SLIDE_HEIGHT, DEFAULT_SLIDE_WIDTH))
                        }
                    }} title='Fullscreen'>
                        <FullScreenIcon />
                    </button>
                </div>
            </div>
        </div>
    </div>

}


export function getAnimatedSlides(slides){
    var thing, animations = [], slideAnimations;
    slides.forEach((slide, slideNo) => {
        slideAnimations = [];

        // Setup slide transition.
        var transition;
        if (slide.transition){
            transition = slide.transition;
        } else {
            transition = transitions.fade;
        }
        var [props, animate] = useSpring(() => ({
             config: { duration: transition.duration, easing: transition.easing },
             from: transition.from,
             reset: true
        }))

        slide.animate = animate;
        slide.props = props;

        slideAnimations.push({ slide, animation: transition, slideNo })

        // Setup animations.
        var thingAnimations;
        slide.content && slide.content.forEach(thing => {
        // slide.content?.forEach(thing => {
            var {props, childrenProps, childrenPropsNonFN} = getAssetSpringProps(
                thing, slide.animations, false)

            // Get all animations for this thing.
            thingAnimations = []
            if (slide.animations){
                slide.animations.forEach(animation => {
                    if (animation.targetKey === thing.key){
                        thingAnimations.push(animation)

                        if (animation.targetChildren && thing.kind !== 'text'){
                            (thing.text.hasOwnProperty('blocks') ? thing.text.blocks : thing.text.body).forEach(line => {
                                slideAnimations.push({ animation, line, slideNo });
                            })
                        } else {
                            slideAnimations.push({ animation, thing, slideNo });
                        }
                    }
                })
            }

            var parentProps = {};
            if (props.left){
                parentProps.left = props.left
                delete props.left
            }

            if (props.top){
                parentProps.top = props.top
                delete props.top
            }

            const [parentSpringProps, parentAnimate] = useSpring(() => (
                Object.keys(parentProps).length ? { from: parentProps, reset: true } : {}))

            thing.parentProps = parentSpringProps
            thing.parentAnimate = parentAnimate


            if (thingAnimations.length){
                props.onRest = () => {
                    thing.completedAnimationValues = {}
                    Object.keys(thing.props).forEach(prop => {
                        thing.completedAnimationValues[prop] = thing.props[prop].get()
                    })
                }
                var [springProps, animate] = useSpring(() => (props))
                thing.animate = animate;
                thing.props = springProps;

            } else {
                thing.props = {};
            }

            if (['bullets', 'numbering'].indexOf(thing.kind) !== -1){
                var splitText = thing.text.body
                if (thing.text.hasOwnProperty('blocks')){
                    splitText = thing.text.blocks
                }

                splitText.forEach(line => {
                    if (thingAnimations.length){
                        var [props, animate] = useSpring(childrenProps)

                        line.animate = animate;
                        line.props = props;

                    } else {
                        line.props = {};
                    }
                })

            } else if (thing.kind === 'text'){
                var words

                if (typeof(thing.text) === 'object'){
                    words = thing.text && thing.text.blocks && thing.text.blocks.map(
                    // words = thing.text?.blocks?.map(
                        block => block.text).join(' ').split(' ')
                } else {
                    words = thing.text.split(' ')
                }

                var [props, animate] = useSprings(words.length, childrenProps)

                thing.children = { animate, props: [] }

                words.forEach((word, i) => {
                    if (thingAnimations.length){
                        thing.children.props.push(props[i])
                    }
                })
            }
        })


        slide.animationCount = slideAnimations.length;
        slide.animationStartIndex = animations.length;
        animations = animations.concat(slideAnimations);
    })

    return [slides, animations];
}


function getInterpolatedAnimation(animation, setImmediate=false){
    var newTransform = []

    var filteredTo = { ...animation.animation.to }
    if (animation.animation.to instanceof Object){
        Object.keys(animation.animation.to).forEach(prop => {
            if (animation.animation.to[prop] instanceof Object){
                // Get all the transform props that need to be merged.
                var isTranformProp = interpolatedProps.indexOf(prop) !== -1

                var propTo = extend({}, animation.animation.to[prop]),
                    template = eval(propTo.template),
                    springProp = propTo.springProp,
                    applyTo = propTo.to

                delete propTo.template
                delete propTo.springProp

                var range = Object.keys(propTo).sort(),
                    output = range.map(value => propTo[value])

                var newProp = {
                    prop: animation.thing.props[springProp].to({
                        range, output }),
                    template
                }

                if (isTranformProp){
                    newTransform.push(newProp)
                } else {
                    animation.thing.props[applyTo].set(newProp)
                }

                delete filteredTo[prop]
            }
        });
    }

    if (newTransform.length){
        var interpolatedTransform = to(
            newTransform.map(n => n.prop),
            function(){
                return newTransform.map((n, i) => n.template(arguments[i])).join(' ')
            })

        if (setImmediate){
            animation.thing.props.transform.set(
                animation.thing.completedAnimationValues['transform'])
        } else {
            animation.thing.props.transform.start({
                config: {
                    duration: animation.animation.duration,
                    easing: prepareEasing(animation.animation.easing)
                },
                to: interpolatedTransform
            })
        }
    }

    return filteredTo
}


var fontsLoaded = []

export var availableGoogleFonts = ['Shrikhand', 'Carter One', 'Lato']

export function loadFont(font){
    if (availableGoogleFonts.indexOf(font) !== -1 && fontsLoaded.indexOf(font) === -1){
        fontsLoaded.push(font)

        // If the font specified isn't loaded, load it.
        var link = document.createElement('link')
        link.rel = 'stylesheet'
        link.href = `https://fonts.googleapis.com/css2?family=${font}&display=swap`
        document.head.appendChild(link)
    }
}


export const AnimatedSlides = forwardRef(({ slides, animations, scale,
    changeAction, slideshowWrapper, setCurrentSlideNo }, slidesWrapper) => {
    const [animationNo, setAnimationNo] = useState(0)

    var prevAnimationNo = useRef(animationNo),
        changeActionRef = useRef(changeAction)

    var moveAnimationBackward = () => {
        if (animationNo >= 1){
            setAnimationNo(animationNo - 1);
        }
    }

    var moveAnimationForward = () => {
        if (animationNo < animations.length - 1){
            setAnimationNo(animationNo + 1)
        }
    }

    useEffect(() => {
        if (changeAction && (!changeActionRef.current || (changeAction.time !== changeActionRef.current.time))){
            if (changeAction.action === 'forward'){
                moveAnimationForward()

            } else if (changeAction.action === 'backward'){
                moveAnimationBackward()

            } else if (changeAction.action === 'set'){
                // var animationNoCursor = 0;
                each(slides, (slide, i) => {
                    if (slide.key === changeAction.value.key){
                        // setAnimationNo(animationNoCursor)
                        setAnimationNo(slide.animationStartIndex)
                        return
                    }
                    // animationNoCursor += slide.animationCount;
                })
            }
        }

        changeActionRef.current = changeAction
    }, [changeAction])

    // Determine which slide we are on
    // var slideNo, cursor = 0;
    // each(slides, (slide, i) => {
    //     slideNo = i;
    //     cursor += slide.animationCount;
    //     if (animationNo < cursor){
    //         return false;
    //     }
    // })

    var slideNo = animations[animationNo].slideNo

    useEffect(() => {
        var animation = animations[animationNo],
            prevAnimation = animations[prevAnimationNo.current];

        if (animation && prevAnimationNo.current <= animationNo){
            setTimeout(() => {
                var whatToAnimate = animation.line || animation.thing || animation.slide,
                    parentAnimationProps = { config: {}, to: {} },
                    animationProps = {},
                    targetChildren = animation.animation.targetChildren || false

                // If this animation is based on a preset/"template" anition.
                if (animation.animation.animation){
                    var presetProps = animationPresets[animation.animation.animation]

                    parentAnimationProps.to = extractAndScalePositionProps(presetProps.to, whatToAnimate, scale)

                    var lengthOfChildren;
                    if (whatToAnimate.kind === 'text'){
                        lengthOfChildren = whatToAnimate.children.props.length
                    } else if (['bullets', 'numbering'].indexOf(whatToAnimate.kind) !== -1){
                        lengthOfChildren = (whatToAnimate.text.hasOwnProperty('blocks') ? (
                            whatToAnimate.text.blocks) : whatToAnimate.text.body).length
                    }

                    animationProps = makeTo(
                        prepareEasing(presetProps.easing), animation.animation.duration || presetProps.duration,
                        extend({}, presetProps.to, targetChildren ? {} : {top: undefined, left: undefined}),
                        animation.animation.delay || presetProps.delay, lengthOfChildren
                    )
                } else {
                    animationProps.config = {
                        duration: animation.animation.duration,
                        easing: prepareEasing(animation.animation.easing)
                    }

                    parentAnimationProps.to = extractAndScalePositionProps(
                        animation.animation.to, whatToAnimate, scale)

                    animationProps.to = extend({}, getInterpolatedAnimation(animation),
                        {top: undefined, left: undefined})
                }

                if (targetChildren && whatToAnimate.kind === 'text'){
                    whatToAnimate.children.animate.start(animationProps)
                } else {
                    whatToAnimate.animate.start(animationProps)
                }

            }, animation.animation.delay || 0)
        }

        if (prevAnimation){
            // If we are moving onto the next slide.
            if (prevAnimationNo.current < animationNo && slideNo !== prevAnimation.slideNo){
                // We need to calculate all the animations on the previous slide.
                var priorAnimations = animations.slice().reverse().slice(
                    animations.length - prevAnimationNo.current - 1)

                var animationsOnPriorSlide = []
                each(priorAnimations, priorAnimation => {
                    if (priorAnimation.slideNo === prevAnimation.slideNo){
                        // End the animation.
                        (priorAnimation.line || priorAnimation.thing || priorAnimation.slide).animate.set(priorAnimation.animation.from)
                    } else {
                        // Break the loop.
                        return false
                    }
                })

            // If we are reversing the last animation.
            } else if (prevAnimationNo.current > animationNo){
                // If this is a slide backwards, we need to set the scene to last animation.
                if (prevAnimation.slideNo > animation.slideNo){
                    each(animations, a => {
                        if (a.slideNo === prevAnimation.slideNo){
                            return false

                        } else if (a.slideNo === animation.slideNo){
                            var animationTo
                            if (a.animation.animation){
                                animationTo = a.animation.to
                            } else {
                                animationTo = extend({}, getInterpolatedAnimation(a, true),
                                    {top: undefined, left: undefined})
                            }

                            if (a.targetKey){
                                animationTo = extend({}, a.targetKey.completedAnimationValues)
                            }

                            (a.line || a.thing || a.slide).animate.set(animationTo)
                        }
                    })
                }

                // Clear the 'to' props that are objects that we use for interpolation.
                var prevAnimationFrom = extend({}, prevAnimation.animation.animation ? (
                    animationPresets[prevAnimation.animation.animation].from) : prevAnimation.animation.from),
                    prevAnimationTo = { ...(prevAnimation.animation.animation ? (
                        animationPresets[prevAnimation.animation.animation].to) : prevAnimation.animation.to) }
                    // prevAnimationTo = extend({}, prevAnimation.animation.animation ? (
                    //     animationPresets[prevAnimation.animation.animation].to) : prevAnimation.animation.to)

                Object.keys(prevAnimationTo).forEach(prop => {
                    if (prevAnimationTo[prop] instanceof Object){
                        delete prevAnimationFrom[prevAnimationTo[prop].springProp]
                        delete prevAnimationFrom[prop]
                    }
                });

                if (prevAnimation.thing && prevAnimation.thing.kind === 'text' && prevAnimation.animation.targetChildren){
                    prevAnimation.thing.children.animate.set(prevAnimationFrom)
                } else {
                    var animatable = prevAnimation.line || prevAnimation.thing || prevAnimation.slide
                    animatable.animate.set(prevAnimationFrom)
                }
            }

            // Notify slides that the slide changed.
            if (slideNo !== prevAnimation.slideNo){
                slideshowWrapper.current.querySelectorAll(`iframe[id^="slate-${slideNo}-"]`).forEach((iframeEl) => {
                    iframeEl.contentWindow.postMessage({ onshow: true }, '*')
                })
            }
        }

        // If there is an animation after this that needs to start with this one.
        var nextAnimation = animations.length > (animationNo + 1) ? animations[animationNo + 1] : null
        if (nextAnimation && nextAnimation.animation.on && nextAnimation.animation.on === 'previous' && prevAnimationNo.current <= animationNo){
            moveAnimationForward();
        }

        // Send this information upwards.
        if (setCurrentSlideNo){
            setCurrentSlideNo(animation.slideNo)
        }

        prevAnimationNo.current = animationNo;
    }, [animationNo])

    var slideKey = animations.find(animation => animation.slideNo === slideNo).slide.key,
        currentSlide = slides.find(slide => slide.key === slideKey)

    return <div className={styles['Slideshow-slideshowAndActions-slideshow-slideWrapper']} style={{
            backgroundColor: currentSlide && currentSlide.background && currentSlide.background.color || null,
            backgroundImage: currentSlide && currentSlide.background && currentSlide.background.image ? `url("data:image/svg+xml;base64,${btoa(patterns[currentSlide.background.image])}")` : null
            // backgroundColor: currentSlide?.background && currentSlide.background.color || null,
            // backgroundImage: currentSlide?.background && currentSlide.background.image ? `url("data:image/svg+xml;base64,${btoa(patterns[currentSlide.background.image])}")` : null
        }} ref={slidesWrapper}>
        <div className={styles['Slideshow-slideshowAndActions-slideshow-slideWrapper-background']}>
            {currentSlide && currentSlide.background && currentSlide.background.preset && backgrounds[currentSlide.background.preset].body}
            {/*currentSlide?.background && currentSlide.background.preset && backgrounds[currentSlide.background.preset].body*/}
        </div>
        <div className={styles['Slideshow-slideshowAndActions-slideshow-slides']} style={{
                width: (scale[0] * DEFAULT_SLIDE_WIDTH) + 'px',
                height: (scale[0] * DEFAULT_SLIDE_HEIGHT) + 'px'
                // width: DEFAULT_SLIDE_WIDTH + 'px', height: DEFAULT_SLIDE_HEIGHT + 'px'
            }}>
            {slides.map((slide, i) => <Slide
                index={i} key={i} slide={slide} scale={scale} isCurrent={slideNo === i} /> )}
        </div>
    </div>
})


var stylesheetsToLoad = [],
    loadedStylesheets = []

export function loadSlideStylesheetsAndFonts(slide){
    slide.content && slide.content.forEach(thing => {
    // slide.content?.forEach(thing => {
        // Go through all things and see which fonts are used.
        if (thing.formatting.fontFamily){
            loadFont(thing.formatting.fontFamily)
        }

        if (thing.kind === 'custom-animation'){
            stylesheetsToLoad.push({
                id: customAnimations[thing.body.animation].id,
                style: customAnimations[thing.body.animation].style
            })
        }
    })

    // See if this slide background uses custom styles.
    if (slide.background && slide.background.preset){
        stylesheetsToLoad.push({
            id: backgrounds[slide.background.preset].id,
            style: backgrounds[slide.background.preset].style
        })
    }

    stylesheetsToLoad.forEach(loadStylesheet)
}


export function loadStylesheet(stylesheet){
    if (loadedStylesheets.indexOf(stylesheet.id) === -1){
        var style = document.createElement('style')
        style.type = 'text/css'
        style.innerHTML = stylesheet.style;
        document.head.appendChild(style);

        loadedStylesheets.push(stylesheet.id)
    }
}


function Slide({ slide, scale, index, isCurrent }){
    const [showPopupBackground, setShowPopupBackground] = useState(false)
    var slideStyle = slide.props //slide.animation ? slide.animation.props : {}

    // if (slide.background){
    //     slideStyle.backgroundColor = slide.background.color
    // }

    useEffect(() => {
        loadSlideStylesheetsAndFonts(slide)
    }, [])


    //<div className='Slideshow-slideshow-slides-slide-debug'>{i}</div>
    return <animated.div className={styles['Slideshow-slideshow-slides-slide'] + (slide.background ? '' : (' '  + styles['background-less']))}
        style={slideStyle}>
        {slide.content && slide.content.map((thing, i) => <Asset
            slideIndex={index} key={i} thing={thing} animations={slide.animations} scale={scale}
            setShowPopupBackground={setShowPopupBackground}
        />)}
        {showPopupBackground ? <div className='Slideshow-slideshow-slides-slide-popupbackground' /> : null}
    </animated.div>
}


export function prepareFonts(){
    // Load font-loading requirements.
    var link = document.createElement('link')
    link.rel = 'preconnect'
    link.href = 'https://fonts.googleapis.com'
    document.head.appendChild(link)

    link = document.createElement('link')
    link.rel = 'preconnect'
    link.href = 'https://fonts.gstatic.com'
    link.crossorigin = true
    document.head.appendChild(link)
}


const SLATE_URL = 'https://slate-eta.vercel.app/show'


export function Asset({ slideIndex, thing, scale, animations, setShowPopupBackground }){
    const [isVisible, setIsVisible] = useState(thing.kind !== 'slate-popup')
    var wrapper = useRef(null)

    var assetID = `${slideIndex}.${thing.key}`
    useEffect(() => {
        setPositionCacheValue(assetID, 'top', thing.formatting.position.top)
        setPositionCacheValue(assetID, 'left', thing.formatting.position.left)
    }, [])

    useEffect(() => {
        thing.parentAnimate.set({ top: getScaledPosition(assetID, scale, 'top', wrapper.current) })
        thing.parentAnimate.set({ left: getScaledPosition(assetID, scale, 'left', wrapper.current) })
    }, [scale])

    var body, preBody;
    if (thing.kind === 'numbering' || thing.kind === 'bullets' || thing.kind === 'text'){
        var textBody;
        if (thing.kind === 'numbering' || thing.kind === 'bullets'){
            textBody = thing.text.hasOwnProperty('blocks') ? thing.text.blocks : thing.text.body
        } else {
            if (typeof(thing.text) === 'object'){
                textBody = thing.text && thing.text.blocks && thing.text.blocks.map(
                // textBody = thing.text?.blocks?.map(
                    block => block.text).join(' ').split(' ')
            } else {
                textBody = thing.text.split(' ')
            }
        }

        var contents = textBody.map((subthing, j) => {
            if (thing.kind === 'text'){
                return <animated.span key={j}
                    style={{ display: 'inline-block', marginRight: '0.2em', ...thing.children.props[j] }}
                >{subthing}</animated.span>
            }

            return <animated.li key={j} style={{ ...subthing.props }}>
                {subthing.text}
            </animated.li>
        });

        var parentStyles = {
            lineHeight: thing.formatting.lineHeight ? (thing.formatting.lineHeight + 'px') : null,
            fontFamily: thing.formatting.fontFamily || null,
            color: thing.formatting.color || null,
            fontSize: thing.formatting.size + 'px',
            textAlign: thing.formatting.textAlign || null,
            height: thing.formatting.height ? (thing.formatting.height + 'px') : null,
            width: thing.formatting.width ? (thing.formatting.width + 'px') : null,
            ...thing.props
        }

        if (thing.kind === 'text'){
            body = <animated.div
                style={{
                    display: 'inline-block',
                    ...parentStyles
                }}
            >{contents}</animated.div>
        } else if (thing.kind === 'numbering'){
            body = <animated.ol style={parentStyles}>{contents}</animated.ol>
        } else {
            body = <animated.ul style={parentStyles}>{contents}</animated.ul>
        }

    } else if (thing.kind === 'image'){
        body = <animated.img
            style={{
                // ...(thing.animation ? thing.animation.props : {}),
                width: thing.formatting.width + 'px', height: thing.formatting.height + 'px'
            }}
            src={thing.src}
        ></animated.img>

    } else if (thing.kind === 'slate'){
        // Custom encode URI in a slate-friendly way.
        /*new URLSearchParams(thing.body.options).toString()*/
        body = <animated.iframe
            id={`slate-${slideIndex}-${thing.key}`}
            loading='lazy'
            style={{
                // ...(thing.animation ? thing.animation.props : {}),
                width: thing.formatting.width + 'px', height: thing.formatting.height + 'px'
            }}
            src={`${SLATE_URL}?${prepareSlateParams(thing.body.options)}`}
        ></animated.iframe>

    } else if (thing.kind === 'slate-popup'){
        preBody = <div style={{ display: isVisible ? 'flex' : 'none', zIndex: 2,
        height: '100%', width: '100%', position: 'absolute' }}>
            <button style={{ fontSize: '24px', position: 'absolute', top: 0, right: 0, margin: '12px' }} onClick={() => {
                setIsVisible(false)
                setShowPopupBackground(false)
            }}><CloseIcon /></button>
            <animated.iframe
                loading='lazy'
                style={{
                    visible: isVisible,
                    height: '80%',
                    width: '80%',
                    margin: 'auto'
                    // ...(thing.animation ? thing.animation.props : {}),
                    // width: thing.width + 'px', height: thing.height + 'px'
                }}
                src={`${SLATE_URL}?${new URLSearchParams(thing.body.options).toString()}`}

            ></animated.iframe>
        </div>

        body = <div>
            <button className={styles['thing-button']} onClick={() => {
                setIsVisible(true)
                setShowPopupBackground(true)
            }}>{thing.body.buttonText}</button>
        </div>

    } else if (thing.kind === 'custom-animation') {
        body = <animated.div
            style={{
                display: 'inline-block',
                fontSize: thing.formatting.size + 'px',
                width: thing.formatting.width ? (thing.formatting.width + 'px') : null,
                height: thing.formatting.height ? (thing.formatting.height + 'px') : null,
                // lineHeight: thing.lineHeight ? (thing.lineHeight + 'px') : null,
                // textAlign: thing.textAlign || null,
                fontFamily: thing.formatting.fontFamily || null,
                color: thing.formatting.color || null,
                ...thing.props
            }}
        >{customAnimations[thing.body.animation].body(thing.body.text)}</animated.div>
    }

    //style={{ ...thing.parentProps
    //top: thing.position.top || null, left: thing.position.left || null,

    return <div>
        {preBody}
        <animated.div className={styles['thing']} ref={wrapper}
            style={{ ...thing.parentProps, transform: `scale(${scale[0]})` }}>
            {body}
        </animated.div>
    </div>
}
