import extend from 'deep-extend';
import {easings} from '@react-spring/web'
import BezierEasing from 'bezier-easing'
import animationPresets from './presets'
import {Buffer} from 'buffer/'

window.Buffer = Buffer;

export function outerHeight(el){
    var height = el.offsetHeight;
    var style = getComputedStyle(el);

    height += parseInt(style.marginTop, 10) + parseInt(style.marginBottom, 10);
    return height;
}

function outerWidth(el){
    var width = el.offsetWidth;
    var style = getComputedStyle(el);

    width += parseInt(style.marginLeft, 10) + parseInt(style.marginRight, 10);
    return width;
}


export function applyScaleToValue(value, scale){
    var newValue = value
    if (scale !== 1){
        var float = parseFloat(newValue),
            unit = newValue.substring(float.toString().length)

        newValue = (float * scale) + unit
    }

    return newValue
}


var originalPositionCache = {}
export function setPositionCacheValue(thing, dimension, value){
    if (!(thing in originalPositionCache)){
        originalPositionCache[thing] = {}
    }

    originalPositionCache[thing][dimension] = value
}

function getPositionCacheValue(thing, dimension, value){
    if (originalPositionCache[thing] && originalPositionCache[thing][dimension]){
        value = originalPositionCache[thing][dimension]
    }

    return value
}

export function getScaledPosition(thing, scale, dimension, el, currentValue){
    var cacheValue = getPositionCacheValue(thing, dimension, currentValue)

    // In case we want to limit which dimension gets scaled.
    // if ((scale[1] === 'width' && dimension === 'left') || (scale[1] === 'height' && dimension === 'top'))

    // In case the assets are not using transform-origin: top left, reduce the value by this:
    // (1 - scale[0]) * OC.$[dimension === 'left' ? 'outerWidth' : 'outerHeight'](el) / 2
    return parseFloat(applyScaleToValue(cacheValue, scale[0])) + 'px'

    // return cacheValue
}


export function extractAndScalePositionProps(to, asset, scale){
    var extractedTo = {}

    if (to.hasOwnProperty('top') || to.hasOwnProperty('left')){
        if (!(asset.key in originalPositionCache)){
            originalPositionCache[asset.key] = { top: null, left: null }
        }

        if (to.hasOwnProperty('top')){
            originalPositionCache[asset.key].top = to.top

            extractedTo.top = getScaledPosition(asset.key, scale, 'top', document.querySelector(`#asset-${asset.key}`))
        }

        if (to.hasOwnProperty('left')){
            originalPositionCache[asset.key].left = to.left

            extractedTo.left = getScaledPosition(asset.key, scale, 'left', document.querySelector(`#asset-${asset.key}`))
        }
    }

    return extractedTo
}

function extractMergeablePositionProps(animation){
    var positionProps = {}

    if (animation.left){
        positionProps.left = animation.left
        delete animation.left
    }

    if (animation.top){
        positionProps.top = animation.top
        delete animation.top
    }

    return positionProps
}


function getFutureAnimationFroms(animation, animations){
    var futureProps = {}

    // Look forward and apply from props.
    animations.slice(animations.indexOf(animation) + 1).forEach(nextAnimation => {
        if ((animation.targetKey === nextAnimation.targetKey) && (animation.targetChildren === nextAnimation.targetChildren)){
            futureProps = extend(futureProps, nextAnimation.animation ? (
                animationPresets[nextAnimation.animation].from) : nextAnimation.from)
        }
    });

    return futureProps;
}


function getPresetProps(animation){
    var presetProps = {},
        animationPreset = animationPresets[animation]

    if (animationPreset.from)
        presetProps.from = animationPreset.from

    presetProps.config = {
        easing: animationPreset.easing,
        duration: animationPreset.duration
    }

    return presetProps
}


export function getAssetSpringProps(asset, animations, hideInitially){
    var props = { reset: true, from: hideInitially ? { visibility: 'hidden' } : {} }

    var animation = animations ? animations.find(an => an.targetKey === asset.key) : null,
        childrenAnimation

    if (animation)
        props.from = extend(props.from, getFutureAnimationFroms(animation, animations))

    //////////////////////////////////////////////////////////////
    if (['text', 'bullets', 'numbering'].indexOf(asset.kind) !== -1){
        var childrenProps = { reset: true, from: {} }

        childrenAnimation = animations ? animations.find(an => (
            an.targetKey === asset.key) && an.targetChildren === true) : null
        // OC.$.findWhere(animations, {
        //     targetKey: asset.key, targetChildren: true }) : null

        if (childrenAnimation)
            childrenProps.from = extend(childrenProps.from, getFutureAnimationFroms(
                childrenAnimation, animations))

        if (childrenAnimation && childrenAnimation.from){
            childrenProps.from = extend(childrenProps.from, childrenAnimation.from)
        }

        if (childrenAnimation){
            childrenProps.config = {
                easing: childrenAnimation.easing,
                duration: childrenAnimation.duration
            }
            // Delay doesn't work imperatively.
            childrenProps.delay = childrenAnimation.delay || 0

            if (childrenAnimation.animation){
                childrenProps = extend(childrenProps, getPresetProps(childrenAnimation.animation))
            }
        }

        childrenProps = extend(childrenProps, extractMergeablePositionProps(childrenProps.from))
        childrenProps.from = childrenProps.from
    }
    ////////////////////////////////////////////////////////////

    if (animation && animation.from){
        props.from = extend(props.from, animation.from)
    }

    if (asset.formatting && asset.formatting.position){
        props.from = extend(props.from, asset.formatting.position)
    }

    // If this is not a scenario where we need to build parent props for
    // children-targeted animation.
    if (animation && !animation.targetChildren){
        props.config = {
            easing: animation.easing,
            duration: animation.duration,
        }
        // Delay doesn't work imperatively.
        props.delay = animation.delay || 0

        if (animation.animation){
            props = extend(props, getPresetProps(animation.animation))
        }
    }

    props = extend(props, extractMergeablePositionProps(props.from))

    return {
        props,
        childrenProps: i => childrenProps
    }
}

/*
export function getSpringPropsFromAnimationTrackItem(animation, animationTrack, textLength){
    var springProps = { reset: true },
        fromProps = textLength ? {} : { visibility: 'hidden' }

    // Look forward and apply from props.
    OC.$.each(animationTrack.slice(animationTrack.indexOf(animation) + 1), nextAnimation => {
        if ((animation.thing === nextAnimation.thing) && (animation.targetChildren === nextAnimation.targetChildren)){
            fromProps = extend(fromProps, nextAnimation.animation ? (
                animationPresets[nextAnimation.animation].from) : nextAnimation.from)
        }
    });


    if (animation.from)
        fromProps = extend(fromProps, animation.from)

    // If this is not a scenario where we need to build parent props for
    // children-targeted animation.
    if (animation && !(animation.targetChildren && !textLength)){
        if (animation.animation){
            var animationPreset = animationPresets[animation.animation]

            if (animationPreset.from)
                fromProps = extend(fromProps, animationPreset.from)

            animation = animationPreset
        }

        springProps.config = {
            easing: animation.easing,
            duration: animation.duration
        }
    }

    springProps = extend(springProps, extractMergeablePositionProps(fromProps))
    springProps.from = fromProps

    if (textLength)
        return i => springProps

    return springProps
}
*/

export function getAnimationScale(wrapper, canScaleUp, defaultHeight=600, defaultWidth=600){
    var scale = 1, widthIsSmaller = true,
        wrapperHeight = outerHeight(wrapper.current),
        wrapperWidth = outerWidth(wrapper.current)

    if (canScaleUp || !(wrapperHeight >= defaultHeight && wrapperWidth >= defaultWidth)){
        // if (wrapperHeight < wrapperWidth)
            // widthIsSmaller = false
        var widthScale = wrapperWidth / defaultWidth,
            heightScale = wrapperHeight / defaultHeight

        if (heightScale < widthScale)
            widthIsSmaller = false

        scale = widthIsSmaller ? widthScale : heightScale
        // scale = (widthIsSmaller ? wrapperWidth : wrapperHeight) /
        //     // 600
    }

    return [scale, widthIsSmaller ? 'width' : 'height']
}


export function makeTo(easing, duration, toProps, delay, textLength){
    return i => {
        var newProps = {
            delay: delay ? (
                typeof(delay) === 'function' ? delay(i, textLength, duration) : delay
            ) : null,
            to: toProps
        }

        if (easing || duration){
            newProps.config = {}

            if (easing)
                newProps.config.easing = easing

            if (duration)
                newProps.config.duration = duration
        }

        return newProps
    }
}


export function prepareEasing(easing){
    if (easing instanceof Array){
        if (easing[0] === 'bezier'){
            return BezierEasing(...easing.slice(1))
        }
    }

    return easing
}
