import React, {createContext} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App, {appLoader, AuthenticatedApp, Dashboard} from './App';
import ImportExport, {ImportableCurriculum} from './pages/import-export'
import TaskPage, {Work, Instructions, Materials, Comments, Review} from './pages/task'
import Settings, {Users as SettingsUsers} from './pages/settings'
import Tasks, {Assigned as TasksAssigned, Submitted as TasksSubmitted, Approved as TasksApproved, Reviewed as TasksReviewed} from './pages/tasks'
import Login, { DropboxRedirect } from './pages/login'
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider, Route } from "react-router-dom"
import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore"


const firebaseConfig = {
  apiKey: "AIzaSyCSuAIyIiFGmg0hnQaAltGID3299zo1Ack",
  authDomain: "explore-499e8.firebaseapp.com",
  databaseURL: "https://explore-499e8.firebaseio.com",
  projectId: "explore-499e8",
  storageBucket: "explore-499e8.appspot.com",
  messagingSenderId: "678044039196",
  appId: "1:678044039196:web:2f51580358ca9d5a8dc7d9"
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    loader: appLoader,
    children: [
        { path: 'login', element: <Login /> },
        { path: 'dropbox-redirect-uri', element: <DropboxRedirect /> },
        {
            path: 'app',
            element: <AuthenticatedApp />,
            children: [
                { path: 'dashboard', element: <Dashboard /> },
                { path: 'settings', element: <Settings />, children: [
                  {
                    path: "users",
                    element: <SettingsUsers />,
                  }
                ] },

                {
                  path: "import-export",
                  element: <ImportExport />,
                  children: [
                    {
                      path: ":curriculumID",
                      element: <ImportableCurriculum />,
                    }
                  ]
                },


                { path: 'tasks', element: <Tasks />, children: [
                  {
                    path: "assigned",
                    element: <TasksAssigned />,
                  },
                  {
                    path: "submitted",
                    element: <TasksSubmitted />,
                  },
                  {
                    path: "reviewed",
                    element: <TasksReviewed />,
                  },
                  {
                    path: "approved",
                    element: <TasksApproved />,
                  }
                ] },


                {
                    path: 'task/:taskID', element: <TaskPage />,

                    children: [
                      {
                        path: "work",
                        element: <Work />,
                      },
                      {
                        path: "instructions",
                        element: <Instructions />,
                      },
                      {
                        path: "materials",
                        element: <Materials />,
                      },
                      {
                        path: "comments",
                        element: <Comments />,
                      },
                      {
                        path: "review",
                        element: <Review />,
                      }
                    ]
                },

            ]
        },

    ]
  },
]);


export const FirebaseContext = createContext({ app });


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <FirebaseContext.Provider value={[app, getFirestore(app)]}>
          <RouterProvider router={router} />
      </FirebaseContext.Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
