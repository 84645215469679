const customAnimations = {
    rainbow: {
        id: 'rainbow',
        title: 'Rainbow',
        properties: [
            { id: 'text', kind: 'string', title: 'Text' }
        ],
        body: (word) => <ul className='CustomAnimation-rainbow'>
            <li className='CustomAnimation-rainbow-layer CustomAnimation-rainbow-white'>{word}</li>
            <li className='CustomAnimation-rainbow-layer CustomAnimation-rainbow-orange'>{word}</li>
            <li className='CustomAnimation-rainbow-layer CustomAnimation-rainbow-red'>{word}</li>
            <li className='CustomAnimation-rainbow-layer CustomAnimation-rainbow-violet'>{word}</li>
            <li className='CustomAnimation-rainbow-layer CustomAnimation-rainbow-blue'>{word}</li>
            <li className='CustomAnimation-rainbow-layer CustomAnimation-rainbow-green'>{word}</li>
            <li className='CustomAnimation-rainbow-layer CustomAnimation-rainbow-yellow'>{word}</li>
        </ul>,

        style: `
        @keyframes rainbow {
         0%, 100% {
          transform: translatey(1rem);
         }
         50% {
          transform: translatey(-1rem);
         }
        }

        .CustomAnimation-rainbow {
            list-style: none;
            padding: 0;
            margin: 0;
            counter-reset: rainbow;
            position: relative;
            display: block;
            padding-left: 0 !important;
            padding: 0 12px;
        }

        .CustomAnimation-rainbow-layer {
            counter-increment: rainbow;
            list-style-type: none !important;
            font-size: 3rem;
            color: #fff;
            text-shadow: -1px -1px 0 #000,
                         1px -1px 0 #000,
                         -1px 1px 0 #000,
                         1px 1px 0 #000,
                         4px 4px 0 rgba(0, 0, 0, .2);
            animation: rainbow 1.5s ease-in-out infinite;
        }

        .CustomAnimation-rainbow-layer:nth-child(1){
            animation-delay: 0.1s;
            left: 1px;
            z-index: 7
            /* z-index: -10*/
        }

        .CustomAnimation-rainbow-layer:nth-child(2){
            animation-delay: 0.2s;
            left: 2px;
            z-index: 6
            /* z-index: -20*/
        }

        .CustomAnimation-rainbow-layer:nth-child(3){
            animation-delay: 0.3s;
            left: 3px;
            z-index: 5
            /* z-index: -30*/
        }

        .CustomAnimation-rainbow-layer:nth-child(4){
            animation-delay: 0.4s;
            left: 4px;
            z-index: 4
            /* z-index: -40*/
        }

        .CustomAnimation-rainbow-layer:nth-child(5){
            animation-delay: 0.5s;
            left: 5px;
            z-index: 3
            /* z-index: -50*/
        }

        .CustomAnimation-rainbow-layer:nth-child(6){
            animation-delay: 0.6s;
            left: 6px;
            z-index: 2
            /* z-index: -60*/
        }

        .CustomAnimation-rainbow-layer:nth-child(7){
            animation-delay: 0.7s;
            left: 7px;
            z-index: 1
            /* z-index: -70*/
        }

        .CustomAnimation-rainbow-layer {
        /* .CustomAnimation-rainbow-layer:not(:first-child) { */
           position: absolute;
           top: 0;
        }

        .CustomAnimation-rainbow-layer-white { color: #fff }
        .CustomAnimation-rainbow-orange { color: #D49C3D }
        .CustomAnimation-rainbow-red { color: #D14B3D }
        .CustomAnimation-rainbow-violet { color: #CF52EB }
        .CustomAnimation-rainbow-blue { color: #44A3F7 }
        .CustomAnimation-rainbow-green { color: #5ACB3C }
        .CustomAnimation-rainbow-yellow { color: #DEBF40 }
        `
    }
}

export default customAnimations
