export const schemas = {
    'https://opencurriculum.org/lessonplan.schema.json': {
      "$schema": "http://json-schema.org/draft-07/schema#",
      "$id": "https://opencurriculum.org/lessonplan.schema.json",
      "type": "array",
      "title": "Lesson plan schema",
      "$defs": {
          "bodyText": {
                "type": "object",
                "properties": {
                    "kind": {
                      "type": "string",
                      "pattern": "text"
                    },
                    "body": {
                      "type": "string",
                    }
                },
                "required": [
                  "kind",
                  "body"
                ]
            },
            "bodyBullets": {
                  "type": "object",
                  "properties": {
                      "kind": {
                        "type": "string",
                        "pattern": "(bullets|numbering)"
                      },
                      "body": {
                        "type": "array",
                        "items": {
                            "anyOf": [
                                {
                                    "type": "object",
                                    "properties": {
                                        "kind": {
                                          "type": "string",
                                          "pattern": "text"
                                        },
                                        "body": {
                                          "type": "string",
                                        }
                                    },
                                    "required": [
                                      "kind",
                                      "body"
                                    ]
                                },
                                { "$ref": "#/$defs/bodyBullets" },
                                { "$ref": "#/$defs/paragraph" }
                            ]
                        }
                      }
                  },
                  "required": [
                    "kind",
                    "body"
                  ]
            },
            "conversation": {
                "type": "object",
                "properties": {
                    "kind": {
                      "type": "string",
                      "pattern": "conversation"
                    },
                    "body": {
                      "type": "array",
                      "items": {
                        "anyOf": [
                            {
                                "type": "object",
                                "properties": {
                                    "doer": {
                                      "type": "string",
                                    },
                                    "message": {
                                      "type": "string",
                                    }
                                },
                                "required": [
                                  "doer",
                                  "message"
                                ]
                            },
                            {
                                "type": "object",
                                "properties": {
                                    "doer": {
                                      "type": "string",
                                    },
                                    "message": {
                                      "type": "array",
                                      "items": {
                                        "anyOf": [
                                            { "$ref": "#/$defs/bodyText" },
                                            { "$ref": "#/$defs/bodyBullets" },
                                            { "$ref": "#/$defs/image" }
                                        ]
                                      }
                                    }
                                },
                                "required": [
                                  "doer",
                                  "message"
                                ]
                            },

                            { "$ref": "#/$defs/image" }


                        ]
                      }
                    }
                },
                "required": [
                  "kind",
                  "body"
                ]
            },

            "image": {
                "type": "object",
                "properties": {
                    "kind": {
                      "type": "string",
                      "pattern": "image"
                    },
                    "src": {
                      "type": "string",
                    }
                },
                "required": [
                  "kind",
                  "src"
                ]
          },

          // Secondary/tertiary.
         "secondaryTertiary": {
             "type": "object",
             "properties": {
                 "kind": {
                   "type": "string",
                   "pattern": "(secondary|tertiary)"
                 },
                 "title": {
                   "type": "string"
                 },
                 "body": {
                     "anyOf": [
                        { "type": "string" },
                        {
                            "type": "array",
                            "items": {
                                "anyOf": [
                                    { "$ref": "#/$defs/bodyText" },
                                    { "$ref": "#/$defs/bodyBullets" },
                                    { "$ref": "#/$defs/image" }
                                ]
                            }
                         }
                     ]

                 }
             },
             "required": [
               "kind",
               "body"
             ]
         },

         "paragraph": {
             "type": "object",
             "properties": {
                 "kind": {
                   "type": "string",
                   "pattern": "(paragraph|subsection)"
                 },
                 "title": {
                   "type": "string",
                 },
                 "body": {
                   "type": "array",
                   "items": {
                       "anyOf": [
                           { "$ref": "#/$defs/bodyText" },
                           { "$ref": "#/$defs/bodyBullets" },
                           { "$ref": "#/$defs/image" }
                       ]
                   }
                 }
             },
             "required": [
               "kind",
               "body"
             ]
         },

         "image": {
             "type": "object",
             "properties": {
                 "kind": {
                   "type": "string",
                   "pattern": "image"
                 },
                 "src": {
                   "type": "string"
                 }
             },
             "required": [
               "kind",
               "src"
             ]
         },

         "handout": {
             "type": "object",
             "properties": {
                 "kind": {
                   "type": "string",
                   "pattern": "handout"
                 },
                 "title": {
                   "type": "string",
                 },
                 "body": {
                   "type": "array",
                   "items": {
                       "anyOf": [
                           { "$ref": "#/$defs/paragraph" },
                           { "$ref": "#/$defs/image" }
                       ]
                   }
                 }
             },
             "required": [
               "kind",
               "body"
             ]
         },



         "sections": {
           "type": "array",
           "items": {
             "anyOf": [
                 { "$ref": "#/$defs/secondaryTertiary" },

                // Image.
                { "$ref": "#/$defs/image" },

                // Handout.
                { "$ref": "#/$defs/handout" },

                // Slate.
                {
                    "type": "object",
                    "properties": {
                        "kind": {
                          "type": "string",
                          "pattern": "slate"
                        },
                        "url": {
                          "type": "string"
                        },
                        "download": {
                          "type": "string"
                        }
                    },
                    "required": [
                      "kind",
                      "url",
                      "download"
                    ]
                },

                // Conversation.
                { "$ref": "#/$defs/conversation" },

                // Paragraph.
                { "$ref": "#/$defs/paragraph" },

                // Subsection.
                {
                    "type": "object",
                    "properties": {
                        "kind": {
                          "type": "string",
                          "pattern": "subsection"
                        },
                        "title": {
                          "type": "string",
                        },
                        "body": {
                          "type": "array",
                          "items": {
                              "anyOf": [

                                  { "$ref": "#/$defs/bodyText" },
                                  { "$ref": "#/$defs/bodyBullets" },
                                  { "$ref": "#/$defs/conversation" },
                                  { "$ref": "#/$defs/secondaryTertiary" }

                              ]
                          }
                        }
                    },
                    "required": [
                      "kind",
                      "body"
                    ]
                }

             ]
           }
       },

       "materials": {
         "type": "array",
         "items": {
             "type": "object",
             "properties": {
                 "name": {
                   "type": "string"
                 },
                 "for": {
                   "type": "string"
                 },
                 "src": {
                   "type": "string"
                 }
             },
             "required": [
               "name",
               "src"
             ]
         }
     }

      },
      "items": {
          "anyOf": [
              {
                  "type": "object",
                  "properties": {
                      "id": {
                        "type": "string"
                      },
                      "kind": {
                        "type": "string",
                        "pattern": "section-title"
                      },
                      "title": {
                        "type": "string"
                      },
                      "duration": {
                        "type": "integer"
                      },
                  },
                  "required": [
                    "id",
                    "kind",
                    "title"
                  ]
              },

              {
                  "type": "object",
                  "properties": {
                      "id": {
                        "type": "string"
                      },
                      "kind": {
                        "type": "string",
                        "pattern": "activity"
                      },
                      "title": {
                        "type": "string"
                      },
                      "duration": {
                        "type": "integer"
                      },
                      "materials": { "$ref": "#/$defs/materials" },
                      "sections": { "$ref": "#/$defs/sections" }
                  },
                  "required": [
                    "id",
                    "kind",
                    "title",
                    "sections"
                  ]
              },


              {
                  "type": "object",
                  "properties": {
                      "kind": {
                        "type": "string",
                        "pattern": "instructions"
                      },
                      "title": {
                        "type": "string"
                      },
                      "duration": {
                        "type": "integer"
                      },
                      "materials": { "$ref": "#/$defs/materials" },
                      "sections": { "$ref": "#/$defs/sections" }
                  },
                  "required": [
                    "kind",
                    "sections"
                  ]
              }
          ]
      }
  },


  "https://opencurriculum.org/lessonplanmenu.schema.json": {
      "$schema": "http://json-schema.org/draft-07/schema#",
      "$id": "https://opencurriculum.org/lessonplanmenu.schema.json",
      "type": "array",
      "title": "Lesson plan menu schema",
      "items": {
          "type": "object",
          "properties": {
            "id": {
              "type": "string"
            },
            "title": {
              "type": "string"
            },
            "time": {
              "type": "integer"
            },
            "items": {
              "type": "array",
              "items": {
                  "type": "object",
                  "properties": {
                    "id": {
                      "type": "string"
                    },
                    "title": {
                      "type": "string"
                    },
                    "time": {
                      "type": "integer"
                    }
                  },
                  "required": [
                    "id",
                    "title"
                  ]
              }
            }
          },
          "required": [
            "id",
            "title",
            "time",
          ]
        }
    }
}


export const instructions = {
    'structure-body-and-menu': `
## Temporary sample text (ignore this "Instructions" tab for now)

1. Download the files.
2. Read them for x
3. Now open the "Work" tab
    `,
    'select-and-rename-images': `
## Temporary sample text (ignore this "Instructions" tab for now)

1. Download the files.
2. Look for all images used in the body of the lesson.
3. Now open the "Work" tab
    `,
    'extract-attachments': `
## Temporary sample text (ignore this "Instructions" tab for now)

1. Download the files.
2. Look for all pages after the main lesson plan.
3. Now open the "Work" tab
    `,
    'make-slides': `
## Temporary sample text (ignore this "Instructions" tab for now)

1. Download the files.
2. Look for all pages after the main lesson plan.
3. Now open the "Work" tab
    `

}
