import { Outlet, Link, useOutlet, useParams } from "react-router-dom"
import { CheckCircleIcon, ChevronRightIcon, CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import {FirebaseContext} from '../index'
import { getDocs, doc, collection, where, query, documentId } from "firebase/firestore"
import {useContext, useEffect, useState} from 'react'
import { useOutletContext, useLocation, useNavigate } from "react-router-dom"
import {UserContext} from '../App'
import { Combobox } from '@headlessui/react'
import {classNames} from '../utils'
import api from '../api'


const ImportableCurriculumItem = ({ tlc }) =>{
    var kind
    if (tlc.kind === 'course'){
       kind = 'Course'
    } else if (tlc.kind === 'module'){
        kind = 'Module'
    } else if (tlc.kind === 'topic'){
        kind = 'Topic'
    } else if (tlc.kind === 'lesson'){
        kind = 'Lesson'
    }

    return <li key={tlc.id}>
        <Link to={`/app/import-export/${tlc.id}`} className="block hover:bg-gray-50">
          <div className="flex items-center px-4 py-4 sm:px-6">
            <div className="flex min-w-0 flex-1 items-center">
              <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                <div>
                  <p className="truncate text-sm font-medium text-indigo-600">{tlc.title}</p>
                  <p className="mt-2 flex items-center text-sm text-gray-500">
                    <span className="truncate">{kind}</span>
                  </p>
                </div>
                <div className="hidden md:block">
                  {/*<div>
                    <p className="text-sm text-gray-900">
                      8/10 tasks incomplete
                    </p>
                    <p className="mt-2 flex items-center text-sm text-gray-500">
                      <CheckCircleIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400" aria-hidden="true" />
                      Pending review
                    </p>
                  </div>*/}
                </div>
              </div>
            </div>
            <div>
              <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
          </div>
        </Link>
      </li>

    // return <div>
    //     // >{tlc.name}</Link>
    //     {myTasks.map(t => <Link to={`/task/${t.id}`}>{t.title}</Link>)}
    // </div>
}


export default function ImportExport(){
    var outlet = useOutlet()
    var params = useParams()
    const [app, db] = useContext(FirebaseContext)
    const [user] = useContext(UserContext)
    let location = useLocation()

    const [tasks, setTasks] = useState()
    const [curricula, setCurricula] = useState()

    const [users, setUsers] = useState()
    const usersAndAll = [{ id: 0, firstName: 'All', lastName: '' }].concat(users || [])
    const [selectedPerson, setSelectedPerson] = useState(usersAndAll[0])

    var isAdmin = user.email === 'varun@opencurriculum.org'

    var setTasksAndCurricula = () => {
        // TODO: Need to incorporate user context.

        // Hardcoding EngageNY.
        api.getCurriculaWithParent(db, params.curriculumID || '9cedf65e').then(curricula => {
            setCurricula(curricula.sort((a, b) => {
                if (a.title && !b.title)
                    return -1
                else if (!a.title && b.title)
                    return 1

                const aName = a.title.toLowerCase()
                const bName = b.title.toLowerCase()

                return aName < bName ? -1 : 1
            }))
        })

        if (params.curriculumID){
            api.getTasks(db, null, null, {parentKind: 'curricula', parentID: params.curriculumID}).then(tasks => {
                setTasks(tasks)
            })
        } else {
            setTasks()
        }

        // api.getTasks(db, /*!isAdmin && */selectedPerson.id !== 0 && selectedPerson.email).then(tasks => {
        //     var curriculaIDs = []
        //
        //     tasks.forEach(task => {
        //         curriculaIDs.push(task.parent)
        //     })
        //     setTasks(tasks)
        //
        //     api.getUserCurricula(db, curriculaIDs).then((curricula) => setCurricula(curricula.sort((a, b) => {
        //             if (a.title && !b.title)
        //                 return -1
        //             else if (!a.title && b.title)
        //                 return 1
        //
        //             const aName = a.title.toLowerCase()
        //             const bName = b.title.toLowerCase()
        //
        //             return aName < bName ? -1 : 1
        //         }))
        //     )
        // })
    }

    useEffect(() => {
        setTasksAndCurricula()

        if (isAdmin){
            api.getUsers(db).then(users => setUsers(users))
        }
    }, [])

    useEffect(() => {
        setTasks()
        setCurricula()

        setTasksAndCurricula()
    }, [selectedPerson, params.curriculumID])

    useEffect(() => {
        var searchParams = new URLSearchParams(location.search)

        if (searchParams.has('assignee') && selectedPerson.email !== searchParams.get('assignee')){
            setSelectedPerson(users.find(u => u.email === searchParams.get('assignee')))
        } else if (!searchParams.has('assignee') && selectedPerson && selectedPerson.id !== 0){
            setSelectedPerson(usersAndAll.find(u => u.id === 0))
        }
    }, [location.search])

    var topLevelCurricula = []
    curricula && curricula.forEach(curriculum => {
        if (!curriculum.parent){
            topLevelCurricula.push(curriculum)
        }
    })

    curricula && curricula.forEach(curriculum => {
        if (topLevelCurricula.indexOf(curriculum) === -1){
            if (!topLevelCurricula.find(tlc => tlc.id === curriculum.parent.id)){
                topLevelCurricula.push(curriculum)
            }
        }
    })

    return <div>
        <header className="bg-white shadow-sm">
          <div className="mx-auto max-w-7xl py-4 px-4 sm:px-6 lg:px-8">
            <h1 className="text-lg font-semibold leading-6 text-gray-900">Import/Export</h1>
          </div>
        </header>

        {!outlet && isAdmin ? <UserFilterWrapper users={usersAndAll} selectedPerson={selectedPerson} /> : null}

        {outlet ? <Outlet context={[tasks, curricula]} /> : <CurriculaAndTopics
            tasks={tasks} curricula={topLevelCurricula} curriculumID={params.curriculumID} />}
    </div>
}


const UserFilterWrapper = ({ users, selectedPerson }) => {
    return <div className="mx-auto max-w-7xl py-4 px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
            <div className="mb-4 sm:flex-auto">
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                <UserFilter users={users} selectedPerson={selectedPerson} />
            </div>
        </div>
    </div>
}


export const UserFilter = ({ users, selectedPerson }) => {
    const [query, setQuery] = useState('')
    let navigate = useNavigate()

    const filteredPeople =
      query === ''
        ? users
        : users.filter((person) => {
            return (person.firstName + ' ' + person.lastName).toLowerCase().includes(query.toLowerCase())
          })

    return (
      <Combobox as="div"
       value={selectedPerson} onChange={(newPerson) => {
           if (newPerson.id !== 0)
               navigate(`?assignee=${newPerson.id}`)
           else
               navigate('')
       }}>
        <div className='flex'>
            <Combobox.Label className="text-sm font-medium text-gray-700 items-center inline-flex mr-4">Show</Combobox.Label>
            <div className="relative mt-1">
              <Combobox.Input
                className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                onChange={(event) => setQuery(event.target.value)}
                displayValue={(person) => `${person && person.firstName}${person && person.id !== 0 ? ' ' : ''}${person && person.lastName}`}
              />
              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </Combobox.Button>

              {filteredPeople && filteredPeople.length > 0 && (
                <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {filteredPeople.map((person) => (
                    <Combobox.Option
                      key={person.id}
                      value={person}
                      className={({ active }) =>
                        classNames(
                          'relative cursor-default select-none py-2 pl-3 pr-9',
                          active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                        )
                      }
                    >
                      {({ active, selected }) => (
                        <>
                          <span className={classNames('block truncate', selected && 'font-semibold')}>{person.firstName} {person.lastName}</span>

                          {selected && (
                            <span
                              className={classNames(
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                                active ? 'text-white' : 'text-indigo-600'
                              )}
                            >
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          )}
                        </>
                      )}
                    </Combobox.Option>
                  ))}
                </Combobox.Options>
              )}
            </div>
        </div>
      </Combobox>
    )
}


const statusBgColors = {
    'pending': 'bg-yellow-500'  //'bg-purple-600', 'bg-yellow-500', 'bg-green-500'
}


function CurriculaAndTopics({ tasks, curricula, curriculumID }){
    const [app, db] = useContext(FirebaseContext)

    var myTasks = tasks && tasks.filter(task => task.parent && task.parent.id === curriculumID)

    return <div className="mx-auto max-w-7xl py-4 px-4 sm:px-6 lg:px-8">
        {myTasks && myTasks.length ? <div>
          <h2 className="text-sm font-medium text-gray-500">Tasks</h2>
          <ul role="list" className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">
            {myTasks.map((t) => (
              <li key={t.title} className="col-span-1 flex rounded-md shadow-sm">

                    <div
                      className={classNames(
                        statusBgColors['pending'],
                        'flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md'
                      )}
                    >
                      {t.title.substring(0,2)}
                    </div>
                    <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-t border-r border-b border-gray-200 bg-white">
                      <div className="flex-1 truncate px-4 py-2 text-sm">
                        <Link to={`/app/task/${t.id}`} className="font-medium text-gray-900 hover:text-gray-600">
                          {t.title}
                        </Link>
                        <p className="text-gray-500">Pending</p>
                      </div>
                    </div>
              </li>
            ))}
          </ul>
        </div> : null}
        <div className="overflow-hidden bg-white shadow sm:rounded-md">
      <ul role="list" className="divide-y divide-gray-200">
        {curricula.map((tlc) => <ImportableCurriculumItem key={tlc.name} tlc={tlc} />)}
      </ul>
    </div>
    </div>

    // return .map( => <ImportableCurriculumItem key={tlc.name} tlc={tlc} />)
}


export function ImportableCurriculum(){
    var params = useParams()
    const [tasks, curricula] = useOutletContext()

    var thisLevelCurricula = []
    curricula && curricula.forEach(plan => {
        if (plan.parent.id === params.curriculumID){
            thisLevelCurricula.push(plan)
        }
    })

    return <div>
        <CurriculaAndTopics tasks={tasks} curricula={thisLevelCurricula} curriculumID={params.curriculumID} />
    </div>
}
