import {easings} from '@react-spring/web'

const transitions = {
    fade: {
        duration: 100,
        easing: easings.easeOutCubic,
        from: { visibility: 'hidden' },
        to: { visibility: 'visible' }
    },
    slideUp: {
        duration: 500,
        easing: easings.easeInOutQuart,
        from: { top: '100%' },
        to: { top: '0%' }
    }
}

export default transitions
