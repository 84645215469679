import {
    getDoc, doc, getDocs, setDoc, collection, where, query, FieldPath,
    runTransaction, serverTimestamp, Timestamp, deleteDoc, updateDoc, arrayUnion,
    documentId
} from "firebase/firestore"
import update from 'immutability-helper'


function getBatchedInSnapshots(db, sourceCollection, inArray){
    var duplicateInArray = inArray.slice(0), batches = []
    while (duplicateInArray.length){
        const batch = duplicateInArray.splice(0, 10)
        batches.push(
            getDocs(query(sourceCollection, where(documentId(), 'in', batch))).then(docsSnapshot => {
                var unsortedDocs = []
                docsSnapshot.forEach(doc => unsortedDocs.push({ id: doc.id, ...doc.data() }))
                return unsortedDocs
            })
        )
    }

    return Promise.all(batches).then(content => content.flat());
}

const API = {
    getUserByEmail: async (db, email) => {
        return getDoc(doc(db, 'users', email)).then(docSnapshot => docSnapshot.data())
    },

    getTemporaryUserByEmail: async (db, email) => {
        return API.getUserByEmail(db, email).then(doc => {
            return doc && doc.isTemporary ? doc : false
        })
    },

    setUserPhoto: async (db, email, photoURL) => {
        return updateDoc(doc(db, 'users', email), { photoURL })
    },

    setUserDropboxToken: async (db, email, dropboxToken) => {
        return updateDoc(doc(db, 'users', email), { dropboxToken })
    },

    getUsers: async (db) => {
        return getDocs(collection(db, 'users')).then(docsSnapshot => {
            var users = []
            docsSnapshot.forEach(doc => users.push({ id: doc.id, ...doc.data() }))
            // (unsortedSteps.sort((a, b) => a.position - b.position)
            // setApps(unsortedApps.sort(app => appIDs.indexOf(app.id)))

            return users
        })
    },

    getTasks: async (db, assignee, status, parent) => {
        var queryPieces = [collection(db, "tasks")]

        if (assignee)
            queryPieces.push(where('assignee', '==', doc(db, "users", assignee)))

        if (status)
            queryPieces.push(where('status', '==', status))

        if (parent)
            queryPieces.push(where('parent', '==', doc(db, parent.parentKind, parent.parentID)))

        return getDocs(query(...queryPieces)).then(docsSnapshot => {
            var tasks = []
            docsSnapshot.forEach(doc => tasks.push({ id: doc.id, ...doc.data() }))
            return tasks.sort((a, b) => {
                if (a.parentTitle && !b.parentTitle)
                    return -1
                else if (!a.parentTitle && b.parentTitle)
                    return 1

                const aName = a.parentTitle.toLowerCase()
                const bName = b.parentTitle.toLowerCase()

                return aName < bName ? -1 : 1
            })
        })
    },

    getTask: async (db, taskID) => {
        return getDoc(doc(db, "tasks", taskID)).then(docSnapshot => {
            return { id: taskID, ...docSnapshot.data() }
        })
    },

    getUserCurricula: (db, curricula) => {
        return getBatchedInSnapshots(db, collection(db, "curricula"), curricula)
    },

    getCurriculaWithParent: (db, parentID) => {
        return getDocs(query(collection(db, 'curricula'), where(
            'parent', '==', doc(db, 'curricula', parentID)))).then(docsSnapshot => {

            var curricula = []
            docsSnapshot.forEach(doc => curricula.push({ id: doc.id, ...doc.data() }))
            return curricula
        })
    },

    getComments: async (db, parentKind, parentID) => {
        const docsSnapshot = await getDocs(query(collection(db, 'comments'), where('parent', '==', doc(db, parentKind, parentID))))
        var comments = []
        docsSnapshot.forEach(doc => comments.push({ id: doc.id, ...doc.data() }))

        var uniqueUserDocs = []
        comments.forEach(comment => {
            if (!uniqueUserDocs.find(doc => doc.id === comment.user.id)){
                uniqueUserDocs.push(comment.user)
            }
        })

        const uniqueUsers = await runTransaction(db, async (transaction) => {
            var uniqueUsers = {}, i
            for (i = 0; i < uniqueUserDocs.length; i++){
                const userDoc = await transaction.get(uniqueUserDocs[i])
                uniqueUsers[userDoc.id] = { id: userDoc.id, ...userDoc.data() }
            }
            return uniqueUsers
        })

        return comments.map(comment => {
            comment.user = uniqueUsers[comment.user.id]
            return comment
        })
    },

    submitComment: async (db, parentKind, parentID, newCommentID, body, user) => {
        setDoc(doc(db, 'comments', newCommentID), {
            body, user: doc(db, 'users', user.email), timestamp: serverTimestamp(),
            parent: doc(db, parentKind, parentID)
        })
    },

    deleteComment: async (db, commentID) => {
        deleteDoc(doc(db, 'comments', commentID))
    },

    updateTaskFile: async (db, taskID, fileName, body) => {
        var path = new FieldPath('body', 'files', fileName, 'value')
        return updateDoc(doc(db, "tasks", taskID), path, body)
    },

    updateTaskBody: async (db, taskID, key, body) => {
        var path = new FieldPath('body', key)
        return updateDoc(doc(db, "tasks", taskID), path, body)
    },

    updateSetup: async (db, taskID, setup) => {
        var path = new FieldPath('body', 'setup')
        return updateDoc(doc(db, "tasks", taskID), path, setup)
    },

    addReviewForTask: async(db, taskID, id, user) => {
        setDoc(doc(db, 'reviews', id), {
            user: doc(db, 'users', user.email), timestamp: serverTimestamp(),
            parent: doc(db, 'tasks', taskID)
        })
    },

    updateReviewFile: async (db, reviewID, fileName, body) => {
        var path = new FieldPath('body', 'files', fileName, 'value')
        return updateDoc(doc(db, "reviews", reviewID), path, body)
    },

    getReviewForTask: async (db, taskID) => {
        return getDocs(query(collection(db, "reviews"), where('parent', '==', doc(db, "tasks", taskID)))).then(docsSnapshot => {
            var reviews = []
            docsSnapshot.forEach(doc => reviews.push({ id: doc.id, ...doc.data() }))
            return reviews.length ? reviews[0] : null
        })
    },

    updateTaskStatus: async (db, taskID, status) => {
        return updateDoc(doc(db, "tasks", taskID), { status })
    },

    mergeReviewIntoTask: async (db, task) => {
        return API.getReviewForTask(db, task.id).then(review => {
            var filenames = Object.keys(review.body.files),
                files = task.body.files

            filenames.forEach(filename => {
                files = update(files, { [filename]: { value: { $set: review.body.files[filename].value } } })
            })

            return updateDoc(
                doc(db, "tasks", task.id),
                { 'body.files': files }
            )
        })
    },

    notifications: {
        markRead: async (db, user, notificationIDs) => {
            var notificationsToDismiss = user.notifications.filter(n => notificationIDs.indexOf(n.id) !== -1),
                data = user.notifications

            notificationsToDismiss.forEach(n => {
                data = update(user.notifications, {[data.indexOf(n)]: { read: {$set: true} } })
            })

            return updateDoc(doc(db, "users", user.email), { notifications: data })
        },

        add: async (db, id, userID, message, url, actorKind, actorID, actionKind, actionID, targetKind, targetID) => {
            var actor
            if (actorKind === 'user'){
                actor = doc(db, "users", actorID)
            }

            var action
            if (actionKind === 'comment'){
                action = doc(db, "comments", actionID)
            }

            var target
            if (targetKind === 'task'){
                target = doc(db, "tasks", targetID)
            }

            return updateDoc(doc(db, "users", userID), {
                notifications: arrayUnion({
                    timestamp: Timestamp.now(),
                    read: false,
                    url, message, id,
                    actor, action, target
                })
            })
        }
    }
}

export default API
