import logo from './logo.svg';
import './App.css';
import { Fragment, useEffect, createContext, useContext, useState } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import {
ListBulletIcon,
MapIcon,
ArrowsRightLeftIcon,
MagnifyingGlassIcon,
ChatBubbleLeftRightIcon,
TagIcon,
PhotoIcon,
  UsersIcon,
} from '@heroicons/react/24/outline'
import { Outlet, useLocation, useOutlet, useNavigate, Link, redirect } from "react-router-dom"
import { getAuth, signOut } from "firebase/auth";
import api from './api'
import Cookies from 'js-cookie'
import {FirebaseContext} from './index'
import {classNames} from './utils'


var fakeUserPhotoURL = 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'

const actions = [
  {
    title: 'Django admin',
    id: 'admin',
    href: 'https://opencurriculum.org/admin',
    icon: ListBulletIcon,
    iconForeground: 'text-teal-700',
    iconBackground: 'bg-teal-50',
    description: 'Official OC database management site. Includes users, courses, units, lessons, strategies, groups, etc.'
  },
  {
    title: 'Strategizer',
    href: 'https://strategizer-d233b.firebaseapp.com/',
    icon: MapIcon,
    id: 'strategizer',
    iconForeground: 'text-purple-700',
    iconBackground: 'bg-purple-50',
    description: 'Strategies for activities in actions, associated principles, and templates for those activities for lesson plans.'
  },
  {
    title: 'Import/Export',
    href: '/app/import-export',
    id: 'import/export',
    icon: ArrowsRightLeftIcon,
    iconForeground: 'text-sky-700',
    iconBackground: 'bg-sky-50',
    description: 'Tools to import textual, images, and other rich metadata curriculum from formats like Word and PDF.'
  },
  {
    title: 'Explore',
    href: '/explore',
    id: 'explore',
    icon: MagnifyingGlassIcon,
    iconForeground: 'text-yellow-700',
    iconBackground: 'bg-yellow-50',
    description: 'Managing the public facing curriculum, sometimes contextualized, in collections on OpenCurriculum.org.'
  },
  {
     id: 'maya-center',
    title: 'Maya Center',
    href: 'https://maya-center.web.app/',
    icon: ChatBubbleLeftRightIcon,
    iconForeground: 'text-indigo-700',
    iconBackground: 'bg-indigo-50',
    description: 'User support and smart-suggestion providing message center.'
  },
  {
    id: 'video-tagger',
    title: 'Video Tagger',
    href: 'https://video-tagger-fb58c.web.app/',
    icon: TagIcon,
    iconForeground: 'text-rose-700',
    iconBackground: 'bg-rose-50',
    description: 'Tagging third-party videos with activity types, displayed during lesson plan design process.'
  },
  {
    id: 'thumbnailer',
    title: 'Thumbnailer',
    href: 'https://thumbnailer-35d46.web.app/',
    icon: PhotoIcon,
    iconForeground: 'text-indigo-700',
    iconBackground: 'bg-indigo-50',
    description: 'Custom thumbnail producer for many lessons and activities of shared units, by overlaying textual titles.'
  },
]


// Taken from https://levelup.gitconnected.com/how-to-turn-settimeout-and-setinterval-into-promises-6a4977f0ace3
const getUserIntervalAsPromise = async (callback, ms, triesLeft = 5) => {
  return new Promise((resolve, reject) => {
      const interval = setInterval(() => {
      var response = callback()
      if (response) {
        resolve(response);
        clearInterval(interval);
      } else if (triesLeft <= 1) {
        reject();
        resolve(undefined);
        clearInterval(interval);
      }
      triesLeft--;
    }, ms);
  });
}


export const appLoader = async ({ params, request }) => {
    const url = new URL(request.url);

    if (!window.loggedIn && url.pathname !== '/login'){
        var currentUser = await getUserIntervalAsPromise(() => {
            return getAuth().currentUser
        }, 500);

        var redirectToURL = window ? window.location.href.substring(
            window.location.origin.length) : url.href.substring(url.origin.length)
        if (!currentUser){
            return redirect(`/login?redirectTo=${redirectToURL}`)
        } else {
            window.loggedIn = true
            return redirect(`${url.pathname === '/' ? '/app' : redirectToURL}`)
        }
    }

    return {}
};


export const UserContext = createContext({});


var attemptingToAuthorize = false

export default function App() {
    const [user, setUser] = useState()
    let navigate = useNavigate()
    let location = useLocation()
    var searchParams
    const [app, db] = useContext(FirebaseContext)

    const redirectUponLogin = () => {
        if (location.pathname === '/login'){
            searchParams = new URLSearchParams(location.search)
            if (!searchParams.get('redirectTo') || searchParams.get('redirectTo') === '/')
                navigate('/app')
            else
                navigate(searchParams.get('redirectTo'))
        }
    }

    useEffect(() => {
        // If this has a one time user access email, fetch that user and set it.
        searchParams = new URLSearchParams(location.search)

        if (!window.loggedIn && (searchParams.has('oneTimeEmailLogin') || Cookies.get('uid'))){
            // Get the user from the API.
            api.getTemporaryUserByEmail(db, searchParams.get('oneTimeEmailLogin') || Cookies.get('uid')).then((authenticatedUser) => {
                if (authenticatedUser){
                    window.loggedIn = true
                    setUser(authenticatedUser)

                    Cookies.set('uid', authenticatedUser.email, { expires: 30 })

                    redirectUponLogin()
                } else {
                    alert('Your account does not have the permission to login.')
                }
            })

        } else {
            getAuth().onAuthStateChanged(function(googleUser){
                if (googleUser){
                    if (!user && !attemptingToAuthorize){
                        attemptingToAuthorize = true

                        // Get the user from the API.
                        api.getUserByEmail(db, googleUser.email).then((authenticatedUser) => {
                            if (authenticatedUser){
                                window.loggedIn = true

                                if (!authenticatedUser.photoURL){
                                    api.setUserPhoto(db, authenticatedUser.email, googleUser.providerData[0].photoURL)
                                }

                                setUser({ ...googleUser.providerData[0], ...authenticatedUser, provider: 'google'
                                    //googleAccessToken: googleUser.accessToken
                                })
                                Cookies.remove('uid')

                                redirectUponLogin()

                            } else {
                                alert('You do not have an account on hub, try again')
                            }
                        })
                    }

                } else {
                    window.loggedIn = false
                }
            })
        }
    }, [])

    useEffect(() => {
        if (window.loggedIn === false && location.pathname !== '/login'){
            navigate('/login')
        }

        if (window.loggedIn && location.pathname === '/'){
            navigate('/app')
        }
    }, [location])

    return <UserContext.Provider value={[user, setUser]}>
        <Outlet />
    </UserContext.Provider>
}


export function Dashboard(){
    const [user] = useContext(UserContext);
    return <div className="py-10">
        <header>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">Dashboard</h1>
          </div>
        </header>

      <div className="p-4 divide-y divide-gray-200 overflow-hidden rounded-lg sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">

        {(user.access.indexOf('all') !== -1 ? actions : actions.filter(a => user.access.indexOf(a.id) !== -1)).map((action, actionIdx) => (
          <div
            key={action.title}
            className={classNames(
              actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
              actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
              actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '',
              actionIdx === actions.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
              'relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'
            )}
          >
            <div>
              <span
                className={classNames(
                  action.iconBackground,
                  action.iconForeground,
                  'rounded-lg inline-flex p-3 ring-4 ring-white'
                )}
              >
                <action.icon className="h-6 w-6" aria-hidden="true" />
              </span>
            </div>
            <div className="mt-8">
              <h3 className="text-lg font-medium">
                {function(){
                    var body = <>
                        {/* Extend touch target to entire panel */}
                        <span className="absolute inset-0" aria-hidden="true" />
                        {action.title}
                    </>

                if (action.href.startsWith('http')){
                    return <a href={action.href} className="focus:outline-none">
                        {body}
                  </a>
                } else {
                    return <Link to={action.href} className="focus:outline-none">
                        {body}
                  </Link>
                }

                }()}
              </h3>
              <p className="mt-2 text-sm text-gray-500">
                {action.description}
              </p>
            </div>
            <span
              className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
              aria-hidden="true"
            >
              <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24">
                <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
              </svg>
            </span>
          </div>
        ))}
      </div>

  </div>

}


export const PageLoading = () => {
    return <div className='py-10'>
        <svg className="animate-spin h-5 w-5 text-black mx-auto" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
        </svg>
    </div>
}


export function AuthenticatedApp(){
    var outlet = useOutlet()
    const [user, setUser] = useContext(UserContext);

    let location = useLocation()
    let navigate = useNavigate()

    const [app, db] = useContext(FirebaseContext)

    const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false)

    let userNavigation = []
    if (user && user.email === 'varun@opencurriculum.org')
        userNavigation.push({ name: 'Settings', href: '/app/settings' })

    var subpage = location.pathname.split('/')[2]

    userNavigation = userNavigation.concat([
        { name: 'Sign out', onClick: () => {
            window.loggedIn = false

            if (user.provider){
                signOut(getAuth()).then(() => {
                    navigate('/')
                })
            } else {
                Cookies.remove('uid')
                navigate('/')
            }

            setUser(undefined)
        }}
    ])

    useEffect(() => {
        if (!outlet){
            navigate('dashboard')
        }
    }, [location])

    useEffect(() => {
        if (user && user.notifications){
            setHasUnreadNotifications(user.notifications.find(n => !n.read))
        }
    }, [user])

    const navigation = [
      { name: 'Dashboard', href: '/app/dashboard', current: subpage === 'dashboard' },
      { name: 'Tasks', href: '/app/tasks', current: subpage === 'tasks' },
      // { name: 'Projects', href: '#', current: false },
      // { name: 'Calendar', href: '#', current: false },
    ]

    if (!user)
        return <PageLoading />

  return (
    <>
      <div className="min-h-full">
        <Disclosure as="nav" className="border-b border-gray-200 bg-white">
          {({ open }) => (
            <>
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 justify-between">
                  <div className="flex">
                    <div className="flex flex-shrink-0 items-center">
                      <Link to='/'>
                          <img
                            className="block h-8 w-auto lg:hidden"
                            src={logo}
                            alt="Your Company"
                          />
                          <img
                            className="hidden h-8 w-auto lg:block"
                            src={logo}
                            alt="Your Company"
                          />
                       </Link>
                    </div>
                    <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                      {navigation.map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          onClick={item.onClick}
                          className={classNames(
                            item.current
                              ? 'border-indigo-500 text-gray-900'
                              : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                            'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium'
                          )}
                          aria-current={item.current ? 'page' : undefined}
                        >
                          {item.name}
                      </Link>
                      ))}
                    </div>
                  </div>
                  <div className="hidden sm:ml-6 sm:flex sm:items-center">

                      {/*<button
                        type="button"
                        className="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      >
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                      </button>*/}

                      <Menu as="div" className="relative inline-block text-left">
                        <div>
                          <Menu.Button style={{ lineHeight: 0 }} className="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" onClick={() => {
                                  if (hasUnreadNotifications){
                                      api.notifications.markRead(db, user, user.notifications.filter(n => !n.read).map(n => n.id))
                                      setHasUnreadNotifications(false)
                                  }
                              }}>
                              <span className="sr-only">View notifications</span>
                              <span className="relative inline-block">
                                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                                  {hasUnreadNotifications ? <span className="absolute top-0 right-0 block h-2 w-2 rounded-full bg-red-400 ring-2 ring-white" /> : null}
                              </span>
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 max-h-64 mt-2 w-72 overflow-auto origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                             {user?.notifications?.sort((a, b) => b.timestamp - a.timestamp).map(notification => <div key={notification.id}>
                               <Menu.Item>
                                 {({ active }) => (
                                   <Link
                                     to={notification.url}
                                     className={classNames(
                                       active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                       'block px-4 py-2 text-sm',
                                       !notification.read ? 'font-bold' : ''
                                     )}
                                   >
                                     {notification.message}
                                 </Link>
                                 )}
                               </Menu.Item>
                            </div>)}
                          </Menu.Items>
                        </Transition>
                      </Menu>

                    {/* Profile dropdown */}
                    <Menu as="div" className="relative ml-3">
                      <div>
                        <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                          <span className="sr-only">Open user menu</span>
                          <img className="h-8 w-8 rounded-full" src={user.photoURL || fakeUserPhotoURL} alt="" referrerPolicy="no-referrer" />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => {
                                  let itemClassNames = [
                                      active ? 'bg-gray-100' : '',
                                      'block px-4 py-2 text-sm text-gray-700'
                                  ]

                                  if (item.onClick){
                                      itemClassNames.push('cursor-pointer')
                                    return <a onClick={item.onClick} className={classNames(...itemClassNames)}>{item.name}</a>

                                  } else {
                                     return <Link
                                        to={item.href}
                                        className={classNames(...itemClassNames)}
                                      >
                                      {item.name}
                                    </Link>
                                  }
                              }}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                  <div className="-mr-2 flex items-center sm:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="sm:hidden">
                <div className="space-y-1 pt-2 pb-3">
                  {navigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className={classNames(
                        item.current
                          ? 'bg-indigo-50 border-indigo-500 text-indigo-700'
                          : 'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800',
                        'block pl-3 pr-4 py-2 border-l-4 text-base font-medium'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
                <div className="border-t border-gray-200 pt-4 pb-3">
                  <div className="flex items-center px-4">
                    <div className="flex-shrink-0">
                      <img className="h-10 w-10 rounded-full" src={user.photoURL || fakeUserPhotoURL} alt="" />
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium text-gray-800">{user.name}</div>
                      <div className="text-sm font-medium text-gray-500">{user.email}</div>
                    </div>
                    {/*<button
                      type="button"
                      className="ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button>*/}
                  </div>
                  <div className="mt-3 space-y-1">
                    {userNavigation.map((item) => (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        <Outlet />


        </div>
    </>
  )

}
