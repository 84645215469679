export function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


let throttleTimeouts = {}

export function throttleCall(ref, fn, seconds){
    clearTimeout(throttleTimeouts[ref.current])
    throttleTimeouts[ref.current] = setTimeout(() => {
        fn.apply(null, Array.prototype.slice.call(arguments, 3))
        clearTimeout(throttleTimeouts[ref.current])
    }, seconds * 1000);
    return throttleTimeouts[ref.current]
}
