import { useState, useRef, useEffect, Fragment, useCallback } from 'react'
import Slides, {DEFAULT_SLIDE_WIDTH, DEFAULT_SLIDE_HEIGHT, prepareFonts, loadFont,
    loadSlideStylesheetsAndFonts, loadStylesheet, availableGoogleFonts} from './slides-animations/Slides'
import {getScaledPosition, applyScaleToValue, setPositionCacheValue} from './slides-animations/utils'
import backgrounds from './slides-animations/backgrounds'
import patterns from './slides-animations/patterns'
import 'react-resizable/css/styles.css'
import { useDrag, useDrop, DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import {List} from 'immutable'
import { v4 as uuidv4 } from 'uuid'
import { Popover, Menu, Transition, Disclosure, Dialog, RadioGroup  } from '@headlessui/react'
import { usePopper } from 'react-popper'
import { Editor, EditorState, ContentState, convertToRaw, convertFromRaw } from 'draft-js'
import 'draft-js/dist/Draft.css'
import { Resizable, ResizableBox } from 'react-resizable'
import { CursorArrowRippleIcon, PlayIcon, CursorArrowRaysIcon } from '@heroicons/react/24/outline'
import './styles/slides.sass'
import {classNames, throttleCall} from './utils'
import update from 'immutability-helper'
import { ChevronRightIcon, EyeIcon, EyeSlashIcon, XMarkIcon, PlusIcon } from '@heroicons/react/20/solid'
import animationPresets from './slides-animations/presets'
import customAnimations from './slides-animations/custom-animations'
import {SLATE_CONTENT_TYPES} from 'slate-client'



var scale = [1]

export function SlidesEditor({ body, save }){
    const [selected, setSelected] = useState(0)
    const [slides, setCurrentSlides] = useState(body)
    const [slidesHistory, setSlidesHistory] = useState([slides])
    const [slidesHistoryRindex, setSlidesHistoryRindex] = useState(0)

    const [moveSlideOpen, setMoveSlideOpen] = useState(false)
    const [selectedContent, setSelectedContent] = useState()
    const [previewSlideshow, setPreviewSlideshow] = useState(false)
    const [designMode, setDesignMode] = useState('formatting')
    const [addSlate, setAddSlate] = useState(false)
    const [addWordArt, setAddWordArt] = useState(false)
    const [addImage, setAddImage] = useState(false)
    const [invisibleContent, setInvisibleContent] = useState([])

    const slidesRef = useRef(null)

    const selectedContentRef = useRef(selectedContent)
    const selectedRef = useRef(selected)

    useEffect(() => {
        selectedContentRef.current = selectedContent
    }, [selectedContent])
    useEffect(() => {
        selectedRef.current = selected
    }, [selected])

    const onCopy = function (ev) {
        if (window.getSelection().toString() === '' && selectedContentRef.current){
            ev.clipboardData.setData('text/json', JSON.stringify({ slide: selectedRef.current, content: selectedContentRef.current }))
            ev.clipboardData.setData('text/plain', window.getSelection());
            ev.preventDefault();
        }
    }

    const onPaste = function (ev) {
        var jsonData = ev.clipboardData.getData('text/json')
        if (jsonData){
            var contentToCopyInfo = JSON.parse(jsonData)

            if (contentToCopyInfo.hasOwnProperty('slide') && contentToCopyInfo.hasOwnProperty('content')){
                setSlides((slides) => {
                    var slideToCopyItFrom = slides.find(slide => slide.key === contentToCopyInfo.slide),
                        contentToCopy = slideToCopyItFrom.content.find(content => content.key === contentToCopyInfo.content)

                    var currentSlideIndex = slides.findIndex(slide => slide.key === selectedRef.current),
                    currentSlide = slides.get(currentSlideIndex)

                    var newContent = [ ...(currentSlide.content || []), {
                        ...contentToCopy, key: uuidv4().substring(0, 4)
                    }]
                    currentSlide = { ...currentSlide, content: newContent }

                    return slides.set(currentSlideIndex, currentSlide)
                })
            }

            ev.preventDefault()
        }

    }

    useEffect(() => {
        prepareFonts()

        document.addEventListener('copy', onCopy)
        document.addEventListener('paste', onPaste)

        return () => {
            document.removeEventListener('copy', onCopy)
            document.removeEventListener('paste', onPaste)

        }

    }, [])

    useEffect(() => {
        if (slidesRef.current && slidesRef.current !== slides){
            save(slides)
        }

        slidesRef.current = slides
    }, [slides])

    var slide = slides.find(slide => slide.key === selected)

    var formatting = {}, asset
    if (selectedContent){
        var assetIndex = slide.content.findIndex(
                content => content.key === selectedContent),
            asset = slide.content[assetIndex]

        if (asset){
            formatting = asset.formatting

            if (asset.kind === 'slate'){
                formatting = {...formatting, ...asset.body.options }

            } else if (asset.kind === 'custom-animation'){
                formatting = {...formatting, ...asset.body }
                delete formatting.animation
            }
        }
    }

    var updateAssetFormatting = (slideKey, contentKey, props) => {
        var currentSlideIndex = slides.findIndex(slide => slide.key === slideKey),
            currentSlide = slides.get(currentSlideIndex)

        setSlides((slides) => {
            var assetIndex = currentSlide.content.findIndex(
                    content => content.key === contentKey),
                content = currentSlide.content,
                asset = content[assetIndex]

            content[assetIndex] = {
                ...asset, formatting: { ...asset.formatting, ...props } }
            currentSlide = { ...currentSlide, content }

            return slides.set(currentSlideIndex, currentSlide)
        })
    }

    var updateAssetText = (slideKey, contentKey, text) => {
        var currentSlideIndex = slides.findIndex(slide => slide.key === slideKey),
            currentSlide = slides.get(currentSlideIndex)

        setSlides((slides) => {
            var assetIndex = currentSlide.content.findIndex(
                    content => content.key === contentKey),
                content = currentSlide.content,
                asset = content[assetIndex]

            content[assetIndex] = { ...asset, text }
            currentSlide = { ...currentSlide, content }

            return slides.set(currentSlideIndex, currentSlide)
        })
    }

    var updateAssetSlateProperties = (slideKey, contentKey, props) => {
        var currentSlideIndex = slides.findIndex(slide => slide.key === slideKey),
            currentSlide = slides.get(currentSlideIndex)

        setSlides((slides) => {
            var assetIndex = currentSlide.content.findIndex(
                    content => content.key === contentKey),
                content = currentSlide.content,
                asset = content[assetIndex]

            content[assetIndex] = {
                ...asset, body: { ...asset.body, options: { ...asset.body.options, ...props } } }
            currentSlide = { ...currentSlide, content }

            return slides.set(currentSlideIndex, currentSlide)
        })
    }


    var updateAssetProperties = (slideKey, contentKey, updater) => {
        var currentSlideIndex = slides.findIndex(slide => slide.key === slideKey),
            currentSlide = slides.get(currentSlideIndex)

        setSlides((slides) => {
            var assetIndex = currentSlide.content.findIndex(
                    content => content.key === contentKey),
                content = currentSlide.content,
                asset = content[assetIndex]

            content[assetIndex] = updater(asset)
            currentSlide = { ...currentSlide, content }

            return slides.set(currentSlideIndex, currentSlide)
        })
    }


    const tabs = [
      { id: 'formatting', name: 'Formatting', current: designMode === 'formatting' },
      { id: 'animation', name: 'Animation', current: designMode === 'animation' }
    ]

    var setSlides = (newSlides) => {
        var setHistory = (ns) => {
            setSlidesHistory(
                slidesHistory.slice(0, slidesHistory.length - slidesHistoryRindex).concat([ns]))
        }

        if (typeof(newSlides) === 'function'){
            setCurrentSlides(currentSlides => {
                const newNewSlides = newSlides(currentSlides)
                setHistory(newNewSlides)
                return newNewSlides
            })
        } else {
            setHistory(newSlides)
            setCurrentSlides(newSlides)
        }

        if (!slidesHistoryRindex){
            setSlidesHistoryRindex(0)
        }
    }

    const addContentToSlide = (content) => {
        setSlides(slides => {
            var updatedSlide = { ...slide },
                updatedSlideIndex = slides.findIndex(slide => slide.key === selected)

            if (!slide.content){
                updatedSlide.content = []
            }

            updatedSlide.content.push(content)

            return slides.set(updatedSlideIndex, updatedSlide)
        })
    }


    return <div className="h-screen">

        <div className="flex h-full">
            <div className="w-44 h-full overflow-auto">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                {slides.map((slide, i) => (
                    <SlideThumbnail onClick={(newSelected) => {
                            if (newSelected !== selected){
                                setSelectedContent()
                                setSelected(newSelected)
                            }
                        }} slide={slide} key={i}
                        isSelected={selected === slide.key} index={i}
                        moveSlide={(key, index) => {
                            setMoveSlideOpen(index)
                        }}
                        duplicateSlide={(key, index) => {
                            setSlides(
                                slides.insert(index + 1, { ...slides.find(slide => slide.key === key), key: uuidv4().substring(0, 5) }))
                        }}
                        removeSlide={(key, index) => {
                            if (selected === key){
                                if (slides.size === 1){
                                    setSelected()
                                } else if (!index){
                                    setSelected(slides.get(1).key)
                                } else if ((slides.size - 1 === index)) {
                                    setSelected(slides.find((slide, i) => i === index - 1).key)
                                } else {
                                    setSelected(slides.find((slide, i) => i === index + 1).key)
                                }
                            }

                            setSlides(slides.delete(index))
                        }}
                    />
                ))}
              </ul>
            </div>


            <div className="grow">
                <div className="m-4">
                    <button
                        type="button"
                        className="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        onClick={() => {
                            var newSlideKey = uuidv4().substring(0, 5)
                            setSelectedContent()
                            setSlides(slides.insert(
                                slides.findIndex(slide => slide.key === selected) + 1, { key: newSlideKey }))
                            setSelected(newSlideKey)
                        }}
                    >
                      Add slide
                    </button>
                    <button
                        type="button"
                        className="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        onClick={() => {
                            setSlides(slides => {
                                var updatedSlide = { ...slide },
                                    updatedSlideIndex = slides.findIndex(slide => slide.key === selected)

                                if (!slide.content){
                                    updatedSlide.content = []
                                }

                                updatedSlide.content.push({
                                    key: uuidv4().substring(0, 4),
                                    kind: 'text',
                                    text: convertToRaw(EditorState.createEmpty().getCurrentContent()),
                                    formatting: {
                                        level: 'title',
                                        position: { top: '70px', left: '100px' },
                                        size: 16, lineHeight: 24,
                                        width: 400
                                    },
                                })

                                return slides.set(updatedSlideIndex, updatedSlide)
                            })

                        }}
                    >
                      Add text
                    </button>
                    <button
                        type="button"
                        className="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        onClick={() => {
                            addContentToSlide({
                                key: uuidv4().substring(0, 4),
                                kind: 'bullets',
                                text: {
                                    entityMap: {},
                                    blocks: [
                                        {
                                          "depth": 0,
                                          "type": "unordered-list-item",
                                          "key": uuidv4().substring(0, 4),
                                          "inlineStyleRanges": [],
                                          "text": "Bullet point 1",
                                          "data": {},
                                          "entityRanges": []
                                        },
                                        {
                                          "depth": 0,
                                          "type": "unordered-list-item",
                                          "key": uuidv4().substring(0, 4),
                                          "inlineStyleRanges": [],
                                          "text": "Bullet point 2",
                                          "data": {},
                                          "entityRanges": []
                                        },
                                        {
                                          "depth": 0,
                                          "type": "unordered-list-item",
                                          "key": uuidv4().substring(0, 4),
                                          "inlineStyleRanges": [],
                                          "text": "Bullet point 3",
                                          "data": {},
                                          "entityRanges": []
                                        },
                                    ]
                                },
                                formatting: {
                                    level: 'paragraph',
                                    position: { top: '90px', left: '36px' },
                                    size: 16, lineHeight: 20,
                                    width: 400
                                },
                            })
                        }}
                    >
                      Add bullets
                    </button>
                    <button
                        type="button"
                        className="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        onClick={() => {
                            addContentToSlide({
                                key: uuidv4().substring(0, 4),
                                kind: 'numbering',
                                text: {
                                    entityMap: {},
                                    blocks: [
                                        {
                                          "depth": 0,
                                          "type": "ordered-list-item",
                                          "key": uuidv4().substring(0, 4),
                                          "inlineStyleRanges": [],
                                          "text": "Number 1",
                                          "data": {},
                                          "entityRanges": []
                                        },
                                        {
                                          "depth": 0,
                                          "type": "ordered-list-item",
                                          "key": uuidv4().substring(0, 4),
                                          "inlineStyleRanges": [],
                                          "text": "Number 2",
                                          "data": {},
                                          "entityRanges": []
                                        },
                                        {
                                          "depth": 0,
                                          "type": "ordered-list-item",
                                          "key": uuidv4().substring(0, 4),
                                          "inlineStyleRanges": [],
                                          "text": "Number 3",
                                          "data": {},
                                          "entityRanges": []
                                        },
                                    ]
                                },
                                formatting: {
                                    level: 'paragraph',
                                    position: { top: '90px', left: '36px' },
                                    size: 16, lineHeight: 20,
                                    width: 400
                                },
                            })
                        }}
                    >
                      Add numbering
                    </button>
                    <button
                        onClick={() => setAddImage(true)}
                        type="button"
                        className="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                      Add image
                    </button>
                    <button
                        onClick={() => setAddSlate(true)}
                        type="button"
                        className="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                      Add slate
                    </button>
                    <button
                        onClick={() => setAddWordArt(true)}
                        type="button"
                        className="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    >
                      Add word art
                    </button>
                    <button
                          type="button"
                          disabled={(slidesHistory.length - 1) === 0}
                          className={classNames("rounded px-2 py-1 text-sm font-semibold ring-1 ring-inset ring-gray-300", (slidesHistory.length - 1) > 0 ? 'text-gray-900 bg-white shadow-sm hover:bg-gray-50' : 'text-gray-300 bg-gray-100')}
                          onClick={() => {
                              // Set a marker for how many steps back.
                              setSlidesHistoryRindex(slidesHistoryRindex => slidesHistoryRindex - 1)

                              setCurrentSlides(slidesHistory[slidesHistory.length + slidesHistoryRindex - 2])
                          }}
                    >
                      Undo
                    </button>
                    <button
                        disabled={!slidesHistoryRindex}
                        type="button"
                        className={classNames("rounded px-2 py-1 text-sm font-semibold ring-1 ring-inset ring-gray-300", slidesHistoryRindex ? 'text-gray-900 bg-white shadow-sm hover:bg-gray-50' : 'text-gray-300 bg-gray-100')}
                        onClick={() => {
                          setSlidesHistoryRindex(slidesHistoryRindex => slidesHistoryRindex + 1)

                          setCurrentSlides(slidesHistory[slidesHistory.length + slidesHistoryRindex])
                        }}
                    >
                      Redo
                    </button>
                </div>


                <div className="px-4 mt-2">
                    {slide ? <DndProvider backend={HTML5Backend}>
                        <Slide
                            key={slide.key}
                            scale={scale} slide={slide}
                            updateAssetFormatting={updateAssetFormatting}
                            updateAssetText={updateAssetText}
                            selectedContent={selectedContent}
                            setSelectedContent={setSelectedContent}
                            invisibleContent={invisibleContent}
                        />
                    </DndProvider> : null}
                </div>
                {selectedContent ? <button
                    type="button"
                    className="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    onClick={() => {
                        var currentSlideIndex = slides.findIndex(slide => slide.key === selected),
                            currentSlide = slides.get(currentSlideIndex)

                        setSelectedContent()
                        setSlides((slides) => {
                            var content = [...slide.content],
                                assetIndex = content.findIndex(
                                    content => content.key === selectedContent)

                            content.splice(assetIndex, 1)
                            currentSlide = { ...currentSlide, content }

                            return slides.set(currentSlideIndex, currentSlide)
                        })
                    }}
                >
                  Delete thing
              </button> : null}
            </div>

            <div className="w-60">

                <div className="text-right">
                    <button
                      type="button" onClick={() => {
                          setPreviewSlideshow({
                              key: slide.key,
                              index: slides.findIndex(slide => slide.key === selected)
                          })
                      }}
                      className="inline-flex items-center gap-x-1.5 rounded-md bg-white text-gray-900 px-3 py-2 text-sm font-semibold shadow-sm hover:bg-gray-50 ring-1 ring-inset ring-gray-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-50"
                    >
                      <PlayIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                      Preview
                    </button>

                </div>

                <div>
                  <div className="sm:hidden">
                    <label htmlFor="tabs" className="sr-only">
                      Select a tab
                    </label>
                    {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                    <select
                      id="tabs"
                      name="tabs"
                      className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                      defaultValue={tabs.find((tab) => tab.current).name}
                    >
                      {tabs.map((tab) => (
                        <option key={tab.name}>{tab.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="hidden sm:block">
                    <nav className="flex space-x-4" aria-label="Tabs">
                      {tabs.map((tab) => (
                        <a
                          key={tab.name}
                          className={classNames(
                            tab.current ? 'bg-gray-200 text-gray-800' : 'text-gray-600 hover:text-gray-800',
                            'rounded-md px-3 py-2 text-sm font-medium cursor-pointer'
                          )}
                          aria-current={tab.current ? 'page' : undefined}
                          onClick={() => {
                              setDesignMode(tab.id)
                          }}
                        >
                          {tab.name}
                        </a>
                      ))}
                    </nav>
                  </div>
                </div>


                {/* Slide properties */}
                {slide && designMode === 'formatting' && !selectedContent ? <div>
                    <SlideBackgroundPicker background={slide.background}
                        slideKey={selected}
                        setValue={(newFormatting) => {
                            var currentSlideIndex = slides.findIndex(slide => slide.key === selected),
                                currentSlide = slides.get(currentSlideIndex)

                            setSlides((slides) => {
                                if (Object.keys(newFormatting).length){
                                    currentSlide = { ...currentSlide, background: newFormatting }
                                } else if (currentSlide.background) {
                                    currentSlide = { ...currentSlide }
                                    delete currentSlide.background
                                }
                                return slides.set(currentSlideIndex, currentSlide)
                            })
                        }}
                    />
                </div> : null}

                {/* Asset properties */}
                {slide && designMode === 'formatting' && selectedContent ? <PropertyEditor
                    selectedContentKey={selectedContent}
                    selectedContent={selectedContent ? asset : null}

                    properties={formatting}

                    formatting={asset.formatting}
                    body={asset.body}

                    setFormatting={newValue => {
                        updateAssetProperties(selected, selectedContent, (asset) => {
                            return { ...asset, formatting: newValue }
                        })
                    }}

                    setBody={newValue => {
                        updateAssetProperties(selected, selectedContent, (asset) => {
                            return { ...asset, body: newValue }
                        })
                    }}

                    setValue={(newValue, isCustom) => {
                        // Extract all the formatting props.
                        // var newFormattingValues = {}, bodyProperties = {}
                        // for (var prop in newValue){
                        //     if (assetFormattingProperties[asset.kind].indexOf(prop) !== -1 && !isCustom){
                        //         newFormattingValues[prop] = newValue[prop]
                        //     } else {
                        //         bodyProperties[prop] = newValue[prop]
                        //     }
                        // }
                        //
                        // if (Object.keys(newFormattingValues).length){
                        //     updateAssetFormatting(selected, selectedContent, newFormattingValues)
                        // }
                        //
                        // if (asset.kind === 'slate' && Object.keys(bodyProperties).length){
                        //     updateAssetSlateProperties(selected, selectedContent, bodyProperties)
                        //
                        // } else if (asset.kind === 'custom-animation' && Object.keys(bodyProperties).length){
                        //     updateAssetProperties(selected, selectedContent, (asset) => {
                        //         return {
                        //             ...asset, body: { ...asset.body, ...bodyProperties }
                        //         }
                        //     })
                        //
                        // } else if (asset.kind === 'slate-popup' && Object.keys(bodyProperties).length){
                        //     updateAssetProperties(selected, selectedContent, (asset) => {
                        //         return {
                        //             ...asset, body: { ...asset.body, ...bodyProperties }
                        //         }
                        //     })
                        // }

                    }}
                /> : null}

                {designMode === 'animation' ? <AnimationBuilder selectedContent={selectedContent}
                    setSelectedContent={setSelectedContent}
                    animations={slide.animations}
                    setAnimations={(newAnimations) => {
                        var currentSlideIndex = slides.findIndex(slide => slide.key === selected),
                            currentSlide = slides.get(currentSlideIndex)

                        setSlides((slides) => {
                            if (Object.keys(newAnimations).length){
                                currentSlide = { ...currentSlide, animations: newAnimations }
                            } else if (currentSlide.animations) {
                                currentSlide = { ...currentSlide }
                                delete currentSlide.animations
                            }
                            return slides.set(currentSlideIndex, currentSlide)
                        })
                    }}
                /> : null}


                {slide?.content ? <div className="my-4">
                    <div className="text-md font-medium">Elements</div>
                    {slide?.content?.map((content, i) => <ContentItem
                        content={content} index={i} key={i}
                        isVisible={invisibleContent.indexOf(content.key) === -1}
                        setVisibility={visible => {
                            setInvisibleContent(invisibleContent => {
                                var newInvisibleContent = [...invisibleContent]

                                if (visible){
                                    var indexOfContent = newInvisibleContent.indexOf(content.key)
                                    newInvisibleContent.splice(indexOfContent, 1)

                                } else {
                                    newInvisibleContent.push(content.key)
                                }

                                return newInvisibleContent
                            })
                        }}
                        selectContent={key => {
                            setSelectedContent(key)
                        }}
                        isSelected={selectedContent === content.key}
                    /> )}
                </div> : null}

            </div>
        </div>
        <MoveSlideModal
            open={moveSlideOpen}
            setOpen={setMoveSlideOpen}
            move={(before, after, toIndex) => {
                const currentSlide = slides.get(moveSlideOpen)
                setSlides((slides) => {
                    var indexToInsertAt = before ? toIndex - 1 : toIndex
                    return slides.insert(indexToInsertAt, currentSlide).delete(
                        toIndex > moveSlideOpen ? moveSlideOpen : moveSlideOpen + 1)
                })
            }}
        />

        <PreviewSlideshowModal
            open={previewSlideshow}
            setOpen={setPreviewSlideshow}
            slides={slides}
        />

        <AddSlatePopup
            open={addSlate}
            setOpen={setAddSlate}
            addSlate={(template, isPopup) => {
                setSlides(slides => {
                    var updatedSlide = { ...slide },
                        updatedSlideIndex = slides.findIndex(slide => slide.key === selected)

                    if (!slide.content){
                        updatedSlide.content = []
                    }

                    updatedSlide.content.push({
                        key: uuidv4().substring(0, 4),
                        kind: isPopup ? 'slate-popup' : 'slate',
                        body: { options: { template }},
                        formatting: isPopup ? {
                            position: { top: '290px', left: '445px' },
                            width: 600,
                            height: 50
                        } : {
                            width: 150,
                            height: 150,
                            position: { top: '120px', left: '225px' }
                        }
                    })

                    return slides.set(updatedSlideIndex, updatedSlide)
                })
            }}
        />

        <AddWordArtPopup
            open={addWordArt}
            setOpen={setAddWordArt}
            addWordArt={(id) => {
                if (customAnimations[id].style){
                    loadStylesheet({ id, style: customAnimations[id].style })
                }

                addContentToSlide({
                    key: uuidv4().substring(0, 4),
                    kind: 'custom-animation',
                    body: { animation: id },
                    formatting: {
                        position: { top: '120px', left: '225px' }
                    }
                })
            }}
        />

        <AddImageModal
            open={addImage}
            setOpen={setAddImage}
            add={(url) => {
                var newImage = document.createElement('img')
                newImage.onload = function (){
                    var widthIsBigger = newImage.width > newImage.height
                    var largeDimensionRatio, finalWidth = newImage.width,
                        finalHeight = newImage.height

                    if (widthIsBigger && newImage.width > DEFAULT_SLIDE_WIDTH){
                        largeDimensionRatio = DEFAULT_SLIDE_WIDTH / newImage.width
                    } else if (newImage.height > DEFAULT_SLIDE_HEIGHT) {
                        largeDimensionRatio = DEFAULT_SLIDE_HEIGHT / newImage.height
                    }

                    if (largeDimensionRatio){
                        finalWidth = largeDimensionRatio * finalWidth
                        finalHeight = largeDimensionRatio * finalHeight
                    }

                    addContentToSlide({
                        key: uuidv4().substring(0, 4),
                        kind: 'image',
                        src: url,
                        formatting: {
                            position: { top: '60px', left: '150px' },
                            width: finalWidth, height: finalHeight
                        }
                    })
                }
                newImage.src = url
            }}
        />

    </div>
}


function ContentItem({ content, index, isVisible, setVisibility, selectContent, isSelected }){
    var kind
    if (content.kind === 'custom-animation'){
        kind = 'Word Art'
    } else {
        kind = content.kind.substring(0, 1).toUpperCase() + content.kind.substring(1).replace('-', ' ')
    }

    return <div className={classNames("flex items-center cursor-pointer p-2 hover:bg-gray-300", isSelected ? 'bg-gray-200' : '')} onClick={(e => selectContent(content.key))}>
      <div className="flex w-0 flex-1 justify-between">
        <div className="w-0 flex-1 text-sm font-medium text-gray-900">{kind} {index + 1}</div>
      </div>
      <div className="ml-4 flex flex-shrink-0">
        <button
          type="button"
          className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          onClick={e => {
              setVisibility(isVisible ? false : true)
              e.stopPropagation()
          }}
        >
          <span className="sr-only">Toggle view</span>
          {isVisible ? <EyeIcon className="h-5 w-5" aria-hidden="true" /> : <EyeSlashIcon className="h-5 w-5" aria-hidden="true" />}
        </button>
      </div>
    </div>
}


function Slide({ scale, slide, updateAssetFormatting, updateAssetText, selectedContent, setSelectedContent, invisibleContent }){
    var wrapperRef = useRef(null)

    var scaleRef = useRef(scale)

    useEffect(() => {
        scaleRef.current = scale
    }, [scale])

    useEffect(() => {
        loadSlideStylesheetsAndFonts(slide)
    }, [slide.key])

    const [{ canDrop, isOver }, dropRef] = useDrop(() => ({
        accept: 'asset',
        drop: (item, monitor) => {
            var offset = monitor.getSourceClientOffset(),
                wrapperBoundingRect = wrapperRef.current.getBoundingClientRect();

            // console.log(offset.y - wrapperBoundingRect.y, offset.x - wrapperBoundingRect.x)
            updateAssetFormatting(slide.key, item.key, { position: {
                top: parseInt((offset.y - wrapperBoundingRect.y) * scaleRef.current) + 'px',
                left: parseInt((offset.x - wrapperBoundingRect.x) * scaleRef.current) + 'px',
            }});
            return ({ name: 'slide' });
        },
        collect: (monitor) => { return {
          isOver: monitor.isOver(),
          canDrop: monitor.canDrop(),
      }},
      canDrop: () => true
    }), [slide])

    return <div ref={useCallback(ref => {
        dropRef(ref)
        wrapperRef.current = ref
    }, [dropRef])} className="bg-white mx-auto shadow relative" style={{
        width: (scale[0] * DEFAULT_SLIDE_WIDTH) + 'px',
        height: (scale[0] * DEFAULT_SLIDE_HEIGHT) + 'px',
        backgroundColor: slide.background && ((
            slide.background.preset && backgrounds[slide.background.preset].fallbackColor) || (slide.background.color)) || null,
        backgroundImage: slide.background && slide.background.image ? `url("data:image/svg+xml;base64,${btoa(patterns[slide.background.image])}")` : null
    }} onClick={() => setSelectedContent()}>

        {slide?.content?.map((thing, i) => <Asset
            slideIndex={i} slideKey={slide.key} key={i} thing={thing} scale={scale}
            isSelected={selectedContent === thing.key}
            select={setSelectedContent}
            updateAssetText={updateAssetText}
            isVisible={invisibleContent.indexOf(thing.key) === -1}
        />)}
    </div>
}


export const PreviewSlideshowModal = ({ slides, open, setOpen }) => {
    const [currentSlideNo, setCurrentSlideNo] = useState()
    const cancelButtonRef = useRef(null)

    var openRef = useRef()
    var slideRef = useRef()

    var beforeRef = useRef()
    var afterRef = useRef()

    var slideshowWrapper = useRef(null)

    useEffect(() => {
        if (slideRef.current && (openRef.current !== open)){
            setTimeout(() => {
                slideRef.current.value = ''
            })

            setCurrentSlideNo(open)
        }

        openRef.current = open
    }, [open])

    return <Transition.Root show={open !== false} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div>
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        Preview
                      </Dialog.Title>

                      <div ref={slideshowWrapper}>
                          {open !== false ? <Slides
                              content={slides.toJS()}
                              slideshowWrapper={slideshowWrapper}
                              currentSlideNo={open}
                              setCurrentSlide={setCurrentSlideNo}
                          /> : null}
                      </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => {
                        setOpen(false)
                    }}
                  >
                    Done
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
}


export const AddSlatePopup = ({ open, setOpen, addSlate }) => {
    const [selected, setSelected] = useState(SLATE_CONTENT_TYPES[0])
    const cancelButtonRef = useRef(null)

    var openRef = useRef()
    var makePopupRef = useRef()

    useEffect(() => {
        if (openRef.current !== open && makePopupRef.current){
            makePopupRef.current.checked = false
        }

        openRef.current = open
    }, [open])


    return <Transition.Root show={open !== false} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div>
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        Select a slate
                      </Dialog.Title>

                      <div className="h-72 overflow-auto">
                          <RadioGroup value={selected} onChange={setSelected}>
                            <RadioGroup.Label className="sr-only">Privacy setting</RadioGroup.Label>
                            <div className="-space-y-px rounded-md bg-white">
                              {SLATE_CONTENT_TYPES.map((contentType, settingIdx) => (
                                <RadioGroup.Option
                                  key={contentType.name}
                                  value={contentType}
                                  className={({ checked }) =>
                                    classNames(
                                      settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                                      settingIdx === SLATE_CONTENT_TYPES.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                                      checked ? 'z-10 border-indigo-200 bg-indigo-50' : 'border-gray-200',
                                      'relative flex cursor-pointer border p-4 focus:outline-none'
                                    )
                                  }
                                >
                                  {({ active, checked }) => (
                                    <>
                                      <span
                                        className={classNames(
                                          checked ? 'bg-indigo-600 border-transparent' : 'bg-white border-gray-300',
                                          active ? 'ring-2 ring-offset-2 ring-indigo-600' : '',
                                          'mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center'
                                        )}
                                        aria-hidden="true"
                                      >
                                        <span className="rounded-full bg-white w-1.5 h-1.5" />
                                      </span>
                                      <span className="ml-3 flex flex-col">
                                        <RadioGroup.Label
                                          as="span"
                                          className={classNames(checked ? 'text-indigo-900' : 'text-gray-900', 'block text-sm font-medium')}
                                        >
                                          {contentType.name}
                                        </RadioGroup.Label>
                                        <RadioGroup.Description
                                          as="span"
                                          className={classNames(checked ? 'text-indigo-700' : 'text-gray-500', 'block text-sm')}
                                        >
                                          something something
                                        </RadioGroup.Description>
                                      </span>
                                    </>
                                  )}
                                </RadioGroup.Option>
                              ))}
                            </div>
                          </RadioGroup>
                        </div>
                  </div>
                </div>


                <div className="bg-gray-50 px-4 py-3 sm:px-6 flex">
                    <div className="relative flex items-start grow">
                      <div className="flex h-6 items-center">
                        <input
                          id="make-popup"
                          ref={makePopupRef}
                          aria-describedby="comments-description"
                          name="make-popup"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                      </div>
                      <div className="ml-3 text-sm leading-6">
                        <label htmlFor="make-popup" className="font-medium text-gray-900">
                          Make popup
                        </label>{' '}
                        <span id="comments-description" className="text-gray-500">
                          <span className="sr-only">Make popup</span>
                        </span>
                      </div>
                    </div>


                    <div className="sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => {
                            addSlate(selected.template, makePopupRef.current.checked)
                            setOpen(false)
                        }}
                      >
                        Done
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        Cancel
                      </button>
                    </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
}


const WORD_ARTS = Object.keys(customAnimations).map(key => customAnimations[key])

export const AddWordArtPopup = ({ open, setOpen, addWordArt }) => {
    const [selected, setSelected] = useState(WORD_ARTS[0])
    const cancelButtonRef = useRef(null)

    return <Transition.Root show={open !== false} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div>
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        Select a word art
                      </Dialog.Title>

                      <div className="h-72 overflow-auto">
                          <RadioGroup value={selected} onChange={setSelected}>
                            <RadioGroup.Label className="sr-only">Privacy setting</RadioGroup.Label>
                            <div className="-space-y-px rounded-md bg-white">
                              {WORD_ARTS.map((wordArt, settingIdx) => (
                                <RadioGroup.Option
                                  key={wordArt.title}
                                  value={wordArt}
                                  className={({ checked }) =>
                                    classNames(
                                      settingIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                                      settingIdx === WORD_ARTS.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                                      checked ? 'z-10 border-indigo-200 bg-indigo-50' : 'border-gray-200',
                                      'relative flex cursor-pointer border p-4 focus:outline-none'
                                    )
                                  }
                                >
                                  {({ active, checked }) => (
                                    <>
                                      <span
                                        className={classNames(
                                          checked ? 'bg-indigo-600 border-transparent' : 'bg-white border-gray-300',
                                          active ? 'ring-2 ring-offset-2 ring-indigo-600' : '',
                                          'mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center'
                                        )}
                                        aria-hidden="true"
                                      >
                                        <span className="rounded-full bg-white w-1.5 h-1.5" />
                                      </span>
                                      <span className="ml-3 flex flex-col">
                                        <RadioGroup.Label
                                          as="span"
                                          className={classNames(checked ? 'text-indigo-900' : 'text-gray-900', 'block text-sm font-medium')}
                                        >
                                          {wordArt.title}
                                        </RadioGroup.Label>
                                        <RadioGroup.Description
                                          as="span"
                                          className={classNames(checked ? 'text-indigo-700' : 'text-gray-500', 'block text-sm')}
                                        >
                                          something something
                                        </RadioGroup.Description>
                                      </span>
                                    </>
                                  )}
                                </RadioGroup.Option>
                              ))}
                            </div>
                          </RadioGroup>
                        </div>


                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => {
                        addWordArt(selected.id)
                        setOpen(false)
                    }}
                  >
                    Done
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
}


function SlideThumbnail({ onClick, slide, isSelected, moveSlide, duplicateSlide, removeSlide, index }){
    var menuItemsRef = useRef()
    var wrapperRef = useRef()
    const [openMenu, setOpenMenu] = useState()

    let [referenceElement, setReferenceElement] = useState()
    let [popperElement, setPopperElement] = useState()
    let { styles, attributes } = usePopper(referenceElement, popperElement)
    var suppressMenu = () => {
        setOpenMenu([false, openMenu[1], openMenu[2]])
    }

    var onClickAnywhere = e => {
        if (openMenu && !menuItemsRef.current.contains(e.target)){
            suppressMenu()
        }
    }

    useEffect(() => {
        if (openMenu){
            document.addEventListener("mousedown", onClickAnywhere);

            return () => {
                document.removeEventListener("mousedown", onClickAnywhere);
            };
        }
    }, [openMenu]);

    var popperStyles = styles.popper
    if (openMenu){
        popperStyles = { ...popperStyles, top: openMenu[2] + 'px', left: openMenu[1] + 'px', zIndex: 1 }
    }

    return <Popover as="div" className="relative">
        <li ref={wrapperRef}
            onClick={() => onClick(slide.key)}
            className={classNames("relative bg-white -mx-4 shadow-sm sm:mx-2 sm:rounded-lg h-24 lg:col-span-2 lg:row-span-2 lg:row-end-2 xl:px-16 xl:pb-20 xl:pt-16 cursor-pointer", isSelected ? 'ring-2 ring-indigo-600' : 'ring-gray-900/5 hover:ring-1 hover:ring-gray-200')}
            onContextMenu={e => {
                var wrapperBoundingRect = wrapperRef.current.getBoundingClientRect()
                setOpenMenu([true, e.clientX - wrapperBoundingRect.x, e.clientY - wrapperBoundingRect.y])
                e.preventDefault()
            }}

        >
            <div className="absolute top-2 left-2 inline-flex items-center rounded-md bg-blue-100 px-1.5 py-0.5 text-xs font-medium text-blue-700">{index + 1}</div>
            <div className="absolute bottom-2 right-2 text-xs text-gray-400">{slide.key}</div>
        </li>

        <div ref={menuItemsRef}>
            <Transition
              as={Fragment}
              show={openMenu && openMenu[0]}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Popover.Panel static
                    //className="absolute right-0 z-50"
                    style={popperStyles}
                    {...attributes.popper}
                >
                    <div className={"z-10 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"} onClick={suppressMenu}>
                        <div>
                            <div className="py-1">
                              <a
                                onClick={() => moveSlide(slide.key, index)}
                                className={classNames(
                                  'hover:bg-gray-100 hover:text-gray-900 text-gray-700 block px-4 py-2 text-sm cursor-pointer'
                                )}
                              >
                                Move...
                              </a>
                            </div>

                            <div className="py-1">
                              <a
                                onClick={() => duplicateSlide(slide.key, index)}
                                className={classNames(
                                  'hover:bg-gray-100 hover:text-gray-900 text-gray-700 block px-4 py-2 text-sm cursor-pointer'
                                )}
                              >
                                Duplicate
                              </a>
                            </div>
                            <div className="py-1">
                              <a
                                onClick={() => removeSlide(slide.key, index)}
                                className={classNames(
                                  'hover:bg-gray-100 hover:text-gray-900 text-gray-700 block px-4 py-2 text-sm cursor-pointer'
                                )}
                              >
                                Delete slide
                              </a>
                            </div>


                        </div>
                    </div>

                  </Popover.Panel>
                </Transition>
        </div>

    </Popover>
}


export const AddImageModal = ({ open, setOpen, add }) => {
    const cancelButtonRef = useRef(null)

    var openRef = useRef()
    var urlRef = useRef()

    useEffect(() => {
        if (urlRef.current && (openRef.current !== open)){
            setTimeout(() => {
                urlRef.current.value = ''
            })
        }

        openRef.current = open
    }, [open])

    return <Transition.Root show={open !== false} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div>
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        Add image
                      </Dialog.Title>

                      <div className="mt-4">
                        <input
                          ref={urlRef}
                          type="text"
                          name="imageurl"
                          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                          placeholder='Image URL'
                        />
                      </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => {
                        add(urlRef.current.value)
                        setOpen(false)
                    }}
                  >
                    Done
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
}


export const MoveSlideModal = ({ open, setOpen, move }) => {
    const cancelButtonRef = useRef(null)

    var openRef = useRef()
    var slideRef = useRef()

    var beforeRef = useRef()
    var afterRef = useRef()

    useEffect(() => {
        if (slideRef.current && (openRef.current !== open)){
            setTimeout(() => {
                slideRef.current.value = ''
            })
        }

        openRef.current = open
    }, [open])

    return <Transition.Root show={open !== false} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div>
                      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                        Move slide
                      </Dialog.Title>

                      <div className="mt-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-2">
                            <div className="flex items-center gap-x-3">
                                <input
                                  id="after"
                                  name="push-notifications"
                                  type="radio"
                                  ref={afterRef}
                                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                />
                                <label htmlFor="after" className="block text-sm font-medium leading-6 text-gray-900">
                                  After
                                </label>
                            </div>
                          </div>

                        <div className="sm:col-span-2">
                            <input
                              ref={slideRef}
                              type="text"
                              name="slideno"
                              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              placeholder={'Slide no.'}
                            />
                        </div>

                        <div className="sm:col-span-2">
                            <div className="flex items-center gap-x-3">
                                <input
                                  id="before"
                                  name="push-notifications"
                                  type="radio"
                                  ref={beforeRef}
                                  className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                />
                                <label htmlFor="before" className="block text-sm font-medium leading-6 text-gray-900">
                                  Before
                                </label>
                            </div>
                        </div>

                      </div>

                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => {
                        move(beforeRef.current.checked, afterRef.current.checked, parseInt(slideRef.current.value))
                        setOpen(false)
                    }}
                  >
                    Done
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>

}


export function Asset({ slideIndex, slideKey, thing, scale, isSelected, select, updateAssetText, isVisible }){
    var assetID = `${slideKey}.${thing.key}`
    var wrapperRef = useRef(null)

    useEffect(() => {
        setPositionCacheValue(assetID, 'top', thing.formatting.position.top)
        setPositionCacheValue(assetID, 'left', thing.formatting.position.left)
    }, [assetID, thing.formatting])

    const [{opacity}, dragRef, previewRef] = useDrag(() => ({
        type: 'asset',
        item: () => {
            return { key: thing.key }
        },
        collect: (monitor) => ({
            opacity: monitor.isDragging() ? 0.5 : 1
        }),
    }), [])

    var body
    if (['text', 'bullets'].indexOf(thing.kind) !== -1){
        if (typeof(thing.text) === 'object'){
            body = <Text body={thing.text} key={assetID} updateBody={body => {
                updateAssetText(slideKey, thing.key, body)
            }} formatting={thing.formatting} />
        } else {
            body = thing.text
        }
    } else if (thing.kind === 'slate'){
        body = <Slate thing={thing} />
    } else if (thing.kind === 'custom-animation') {
        body = customAnimations[thing.body.animation].body(thing.body.text)
    } else if (thing.kind === 'slate-popup'){
        body = <button className='bg-blue-600 text-white hover:bg-blue-700 p-3 text-md rounded'>{thing.body.buttonText}</button>
    } else {
        body = <img src={thing.src} />
    }

    const width = thing.formatting.width ? (scale * thing.formatting.width) : null,
        height = thing.formatting.height ? (scale * thing.formatting.height) : null

    return <div ref={useCallback(ref => {
        previewRef(ref)
        wrapperRef.current = ref
    }, [previewRef])} className={classNames('Asset absolute', thing.kind)} style={{
            opacity, display: isVisible ? 'block' : 'none',
            top: getScaledPosition(assetID, scale, 'top', wrapperRef.current, thing.formatting.position.top),
            left: getScaledPosition(assetID, scale, 'left', wrapperRef.current, thing.formatting.position.left),
            width: width ? width + 'px' : null,
        }}>
            <div ref={dragRef} className="Asset-move z-10 top-0 left-0 w-6 h-6 absolute cursor-move"><CursorArrowRippleIcon /></div>
            <ResizableBox
                width={width}
                height={height || 200}
            >
            <div className={classNames("border-dashed border-transparent hover:border-gray-400 border-2", isSelected ? 'border-gray-400' : '')} style={{
                width: '100%', height: '100%',
                display: 'inline-block',
                overflow: 'hidden',
                lineHeight: thing.formatting.lineHeight ? (thing.formatting.lineHeight + 'px') : null,
                fontSize: thing.formatting.size ? (thing.formatting.size + 'px') : null,
                textAlign: thing.formatting.textAlign || null,
                fontFamily: thing.formatting.fontFamily || null,
                color: thing.formatting.color || null,
            }} onClick={e => {
                select(thing.key)
                e.stopPropagation()
            }}>{body}</div>
        </ResizableBox>


    </div>
}


const IframeSelector = () => {
    return <div className='absolute cursor-pointer rounded bottom-2 right-2 w-8 h-8 bg-blue-500 p-1'>
        <span className="text-white"><CursorArrowRaysIcon /></span>
    </div>
}


function Slate({ thing }){
    // First get the content type from the template.
    var contentType = SLATE_CONTENT_TYPES.find(
        contentType => contentType.template === thing.body.options.template)

    // Then render the editable from the content type.
    let slateProperties = { ...thing.body.options }
    delete slateProperties.template
    return contentType.component({ properties: slateProperties }, {}, {}, {IframeSelector})
}


export var Text = ({ body, formatting, updateBody }) => {
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty())
    const [isEditing, setIsEditing] = useState()

    const bodyRef = useRef()
    // const keyRef = useRef(thingKey)
    const isEditingRef = useRef()
    const editorRef = useRef()

    useEffect(() => {
        if (body && body !== bodyRef.current && !isEditing/* && thingKey === keyRef.current*/){
            var newEditorState = EditorState.createWithContent(convertFromRaw(body))
            setEditorState(newEditorState)

            bodyRef.current = body
        }
    }, [body])

    return <div>
        <Editor editorState={editorState}
            placeholder={`Add some text`}
            blockStyleFn={function(formatting, block){
                if (formatting && formatting.textAlign)
                    return `textAlign-${formatting.textAlign}`
            }.bind(this, formatting)}
            onChange={(newEditorState) => {
                setIsEditing(true)
                setEditorState(newEditorState)
            }}
            onBlur={() => {
                updateBody(convertToRaw(editorState.getCurrentContent()))
                setTimeout(() => setIsEditing(false))
            }}
            ref={ref => editorRef.current = ref}
        />
    </div>
}


const availableProperties = {
    level: {
        id: 'level', title: 'Paragraph level', kind: 'string'
    },
    position: {
        id: 'position', kind: 'object', title: 'Position', items: [
            { id: 'top', kind: 'string', title: 'Top' },
            { id: 'left', kind: 'string', title: 'Left' }
        ]
    },

    color: {
        id: 'color', title: 'Color', kind: 'string'
    },

    size: {
        id: 'size', title: 'Size', kind: 'number'
    },

    lineHeight: {
        id: 'lineHeight', title: 'Line Height', kind: 'number'
    },

    textAlign: {
        id: 'textAlign', title: 'Text align', kind: 'string'
    },

    fontFamily: {
        id: 'fontFamily', title: 'Font family', kind: 'string',
        options: ['Arial'].concat(availableGoogleFonts),
        onSelect: e => loadFont(e.target.value)
    },

    width: {
        id: 'width', title: 'Width', kind: 'number'
    },

    height: {
        id: 'height', title: 'Height', kind: 'number'
    }

}


function AnimationBuilder({ selectedContent, animations, setAnimations, setSelectedContent }){
    const [currentlyOpenAnimation, setCurrentlyOpenAnimation] = useState(false)

    useEffect(() => {
        if (currentlyOpenAnimation){
            var animation = animations.find(animation => animation.key === currentlyOpenAnimation)
            if (animation.targetKey !== selectedContent){
                setSelectedContent(animation.targetKey)
            }
        }
    }, [currentlyOpenAnimation])

    return <div>
        <div className="my-2 space-y-4">
            {animations?.map((animation, i) => {
                return <AnimationItem index={i} key={animation.key} animation={animation} setAnimation={(key, props) => {
                    var currentAnimationIndex = animations.findIndex(animation => animation.key === key)
                    var newAnimations = [ ...animations]
                    newAnimations[currentAnimationIndex] = props
                    setAnimations(newAnimations)
                }} onOpen={(key) => {
                    setCurrentlyOpenAnimation(key)
                }} onClose={() => setCurrentlyOpenAnimation()} shouldBeOpen={currentlyOpenAnimation === animation.key}
                    deleteAnimation={(key) => {
                        var currentAnimationIndex = animations.findIndex(animation => animation.key === key)
                        var newAnimations = [ ...animations ]
                        newAnimations.splice(currentAnimationIndex, 1)
                        setAnimations(newAnimations)
                    }}
                />
            })}
        </div>
        {selectedContent ? <button
          type="button"
          className="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          onClick={() => {
              var newAnimations = animations ? [ ...animations ] : []
              newAnimations.push(
                  { key: uuidv4().substring(0, 5), targetKey: selectedContent })
              setAnimations(newAnimations)
          }}
        >
          Add animation
      </button> : null}


    </div>
}


const availableEasings = [
    ['linear'],
    ['bezier', 0.175, .885, .32, 1],
]


function AnimationItem({ index, animation, setAnimation, deleteAnimation, onOpen, shouldBeOpen, onClose }){
    const [focused, setFocused] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const isOpenRef = useRef(isOpen)
    const [isAnimationCustom, setIsAnimationCustom] = useState(false)

    useEffect(() => {
        if (animation.from){
            setIsAnimationCustom(true)
        }
    }, [animation])

    useEffect(() => {
        if (shouldBeOpen !== isOpen){
            setIsOpen(shouldBeOpen)
        }
    }, [shouldBeOpen])

    // useEffect(() => {
    //     if (isOpen !== isOpenRef.current){
    //         if (isOpen)
    //             onOpen(animation.key)
    //         else {
    //             console.log(shouldBeOpen)
    //             onClose()
    //         }
    //     }
    //
    //     isOpenRef.current = isOpen
    // }, [isOpen])

    var onFocus = e => setFocused(true),
        onBlur = e => setFocused(false)

    var setItem = function(property, value){
        if (['', null, undefined].indexOf(value) !== -1){
            if (animation.hasOwnProperty(property)){
                setAnimation(animation.key, update(animation, { $unset: [property] }))
            }
        } else {
            setAnimation(animation.key, update(animation, { [property]: { $set: value } }))
        }
        // setItem('preset', value)
        // if (property === 'preset'){
        //     if (value === 'none'){
        //         setData({})
        //     } else {
        //         setData({ [property]: value })
        //     }
        // } else {
        // }

    }

    return <div className="border rounded border-gray-200">
        <div className="flex items-center cursor-pointer p-2 hover:bg-gray-200" onClick={() => { isOpen ? onClose() : onOpen(animation.key)}}>
          <div className="flex w-0 flex-1 justify-between">
            <div className="w-0 flex-1 text-sm font-medium text-gray-900">Animation {index + 1}</div>
          </div>
          <div className="ml-4 flex flex-shrink-0">
            <button
              type="button"
              className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <span className="sr-only">Toggle open</span>
              <ChevronRightIcon className={classNames("h-5 w-5", isOpen ? 'rotate-90 transform' : '')} aria-hidden="true" />
            </button>
          </div>
        </div>


        <div className={classNames("text-sm bg-gray-200 p-2", isOpen ? '' : 'hidden')}>
            <SingleProperty
                property={{
                    id: 'duration', title: 'Duration', kind: 'number'
                }} setValue={setItem} defaultValue={animation?.duration || ''}
                focused={focused} onFocus={onFocus} onBlur={onBlur}
            />
            <SingleProperty
                property={{
                    id: 'targetChildren', title: 'Animate words', kind: 'boolean'
                }} setValue={function(propertyTitle, value){
                    if (value){
                        setItem(propertyTitle, value)
                    } else {
                        setItem(propertyTitle, null)
                    }
                }} defaultValue={animation?.targetChildren || ''}
                focused={focused} onFocus={onFocus} onBlur={onBlur}
            />
            <SingleProperty options={['none', 'custom'].concat(Object.keys(animationPresets))}
                property={{
                    id: 'animation', title: 'Animation', kind: 'string'
                }} setValue={function(propertyTitle, value){
                    if (['none', 'custom', 'appear'].indexOf(value) !== -1){
                        if (value === 'custom' && !isAnimationCustom){
                            setIsAnimationCustom(true)
                        } else if (value === 'none' && isAnimationCustom) {
                            setIsAnimationCustom(false)
                        }

                        setItem('animation', null)
                        return

                    } else if (isAnimationCustom){
                        setIsAnimationCustom(false)
                    }

                    setItem('animation', value)

                }} defaultValue={isAnimationCustom ? 'custom' : (animation?.animation || '')}
                focused={focused} onFocus={onFocus} onBlur={onBlur}
            />


            {/* Custom animation settings */}
            {isAnimationCustom ? <>
            <SingleProperty options={availableEasings}
                optionsRenderFn={(easing, i) => <option value={i}>{easing.join(', ')}</option>}
                property={{
                    id: 'easing', title: 'Easing', kind: 'custom'
                }} setValue={function(propertyTitle, value){
                    if (value !== '0'){
                        setItem(propertyTitle, availableEasings[parseInt(value, 10)])
                    } else {
                        setItem('easing', null)
                    }
                }} defaultValue={animation?.easing || ''}
                focused={focused} onFocus={onFocus} onBlur={onBlur}
            />
            <SingleProperty
                property={{
                    id: 'animationFrom', title: 'From', kind: 'text'
                }} setValue={function(propertyTitle, value){
                    try {
                        setItem('from', JSON.parse(value))
                    } catch(e){}
                }} defaultValue={animation?.from && typeof(animation.from) === 'object' ? JSON.stringify(animation.from) : (animation.from || '')}
                focused={focused} onFocus={onFocus} onBlur={onBlur}
            />
            <SingleProperty
                property={{
                    id: 'animationTo', title: 'To', kind: 'text'
                }} setValue={function(propertyTitle, value){
                    try {
                        setItem('to', JSON.parse(value))
                    } catch(e){}
                }} defaultValue={animation?.to && typeof(animation.to) === 'object' ? JSON.stringify(animation.to) : (animation.to || '')}
                focused={focused} onFocus={onFocus} onBlur={onBlur}
            />
            </> : null}
            {/* End of custom animation settings */}

            <SingleProperty
                property={{
                    id: 'delay', title: 'Delay', kind: 'number'
                }} setValue={setItem} defaultValue={animation?.delay || ''}
                focused={focused} onFocus={onFocus} onBlur={onBlur}
            />
            <SingleProperty options={[
                    { value: 'click', title: 'Click' },
                    { value: 'previous', title: 'Previous' }
                ]}
                property={{
                    id: 'on', title: 'On', kind: 'string'
                }} setValue={function(propertyTitle, value){
                    if (value === 'click'){
                        setItem('on', null)
                    } else {
                        setItem('on', value)
                    }
                }} defaultValue={animation?.on || ''}
                focused={focused} onFocus={onFocus} onBlur={onBlur}
            />
            <button
              type="button"
              className="rounded bg-white px-2 py-1 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              onClick={() => {
                  deleteAnimation(animation.key)
              }}
            >
              Delete animation
            </button>
        </div>
    </div>
}


const assetFormattingProperties = {
    'text': ['level', 'position', 'color', 'size', 'lineHeight', 'textAlign', 'fontFamily', 'width', 'height'],
    'bullets': ['level', 'position', 'color', 'size', 'lineHeight', 'textAlign', 'fontFamily', 'width', 'height'],
    'slate':  ['position', 'width', 'height'],
    'slate-popup':  ['position', 'width', 'height'],
    'custom-animation':  ['position', 'width', 'height', 'size', 'lineHeight', 'fontFamily'],
    'image':  ['position', 'width', 'height']
}


export default function PropertyEditor({ selectedContentKey, selectedContent, setFormatting, setBody, formatting, body
    // properties, setValue,
    }){
    // const [data, setData] = useState(properties || {})
    const selectedContentRef = useRef(selectedContentKey)
    const [focused, setFocused] = useState(false)

    const [formattingData, setFormattingData] = useState(formatting || {})
    const [bodyData, setBodyData] = useState(body || {})


    var onFocus = e => setFocused(true),
        onBlur = e => setFocused(false)

    // useEffect(() => {
    //     if (data && Object.keys(data).length){
    //         setValue(data)
    //     }
    // }, [data])

    useEffect(() => {
        if (formattingData && Object.keys(formattingData).length){
            setFormatting(formattingData)
        }
    }, [formattingData])

    useEffect(() => {
        if (bodyData && Object.keys(bodyData).length){
            setBody(bodyData)
        }
    }, [bodyData])

    useEffect(() => {
        // setData(properties || {})

        setFormattingData(formatting || {})
        setBodyData(body || {})

        selectedContentRef.current = selectedContentKey
    }, [selectedContentKey])

    var propertiesBody = []
    //, key
    // for (key in properties){
    var assetProperties = assetFormattingProperties[selectedContent.kind], contentType, customProperties
    if (selectedContent.kind === 'slate'){
        contentType = SLATE_CONTENT_TYPES.find(
            contentType => contentType.template === selectedContent.body.options.template)
        customProperties = contentType.properties

    } else if (selectedContent.kind === 'custom-animation'){
        customProperties = customAnimations[selectedContent.body.animation].properties

    } else if (selectedContent.kind === 'slate-popup'){
        customProperties = [{
            id: 'buttonText', title: 'Button text', kind: 'string'
        }]
    }

    if (customProperties){
        assetProperties = assetProperties.concat(customProperties.map(prop => {
            prop.isBody = true
            return prop
        }))
    }

    var placeChange = (change, isBody) => (
        isBody && selectedContent.kind === 'slate' ? { options: change } : change),
        getChangeLocation = (data, isBody) => isBody && selectedContent.kind === 'slate' ? data.options : data

    var setItem = (propertyID, itemID, propertyTitle, value, isBody) => {
        var property, newData, dataToUpdate = isBody ? bodyData : formattingData,
            fnToUpdate = isBody ? setBodyData : setFormattingData, propertyIndex

        if (availableProperties.hasOwnProperty(propertyID) && !isBody){
            property = availableProperties[propertyID]
        } else {
            property = customProperties.find(cp => cp.id === propertyID)
        }

        // if (['slate', 'custom-animation', 'slate-popup'].indexOf(selectedContent.kind) !== -1){
        //["string", "text", "boolean", "number"].indexOf(kind) !== -1
        if (property.kind === 'list'){
            var dataLocation = getChangeLocation(dataToUpdate, isBody)
                // indexOfItem = dataLocation[propertyID].findIndex(it => it.id === itemID)

            if (property.items.kind === 'object'){
                propertyIndex = dataToUpdate[propertyID][itemID].items.findIndex(
                    p => p.title === propertyTitle)
                fnToUpdate(update(dataToUpdate, placeChange(
                    { [propertyID]: { [itemID]: { items: { [propertyIndex]: { $set: value } } } } }, isBody) ))

            } else {
                fnToUpdate(update(dataToUpdate, placeChange(
                    { [propertyID]: { [itemID]: { $set: value } } }, isBody) ))
            }

        } else if (property.kind === 'object'){
            if (dataToUpdate[propertyID]){
                fnToUpdate(update(dataToUpdate, placeChange({ [propertyID]: { [propertyTitle]: { $set: value } }}, isBody) ))
            } else {
                fnToUpdate(update(dataToUpdate, placeChange({ [propertyID]: { $set: { [propertyTitle]: value } } }, isBody) ))
            }

        } else {
            fnToUpdate(update(dataToUpdate, placeChange({ [propertyID]: { $set: value } }, isBody) ))
        }
    }

    var deleteItem = (propertiesIndex, propertyID, itemID, isBody) => {
        var dataToUpdate = isBody ? bodyData : formattingData,
            fnToUpdate = isBody ? setBodyData : setFormattingData

        if (assetProperties.find(ap => (ap === propertyID) || (
            ap.id === propertyID)).kind === 'list'){
            var dataLocation = getChangeLocation(dataToUpdate, isBody)
                // indexOfItem = dataLocation[propertyID].findIndex(it => it.id === itemID)

            fnToUpdate(update(dataToUpdate, placeChange(
                { [propertyID]: { $splice: [[itemID, 1]] } }, isBody)))
        }
    }

    assetProperties.forEach((key, i) => {
        var propertyDescription = typeof(key) === 'object' ? key : availableProperties[key],
            propertyName = typeof(key) === 'object' ? key.id : key

        var defaultValue, bodyEl,
            propertiesToGetDefaultValueFrom = propertyDescription.isBody ? body : formatting

        if (selectedContent.kind === 'slate' && propertyDescription.isBody){
            propertiesToGetDefaultValueFrom = propertiesToGetDefaultValueFrom.options
        }

        if (propertyDescription.kind === 'list'){
            bodyEl = [
                    <div key={0}>{propertiesToGetDefaultValueFrom && propertiesToGetDefaultValueFrom[propertyDescription.id] && propertiesToGetDefaultValueFrom[propertyDescription.id].sort((a, b) => a.position - b.position).map((listItem, j) => <ListItem
                    propertiesIndex={i}
                    propertyID={propertyDescription.id}
                    kind={propertyDescription.items.kind} isBody={propertyDescription.isBody}
                    key={j} index={j} item={propertyDescription.items} properties={propertyDescription.items}
                    setItem={setItem} deleteItem={deleteItem}
                    defaultValue={listItem/*propertiesToGetDefaultValueFrom && propertiesToGetDefaultValueFrom[propertyDescription.id]?.find(it => it.id === listItem.id)*/}
                />
                )}</div>,
                <div key={1}><button
                    type="button"
                    className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => {
                        setBodyData(data => {
                            var targetDataPath = data[propertyDescription.id]
                            if (selectedContent.kind === 'slate' && propertyDescription.isBody){

                                if (!(data.options && data.options[propertyDescription.id])){
                                    data.options[propertyDescription.id] = []
                                }

                                targetDataPath = data.options[propertyDescription.id]

                            } else if (!data[propertyDescription.id]){
                                data[propertyDescription.id] = []
                            }

                            var id = uuidv4().substring(0, 8)
                            if (propertyDescription.items.kind === "object"){
                                var newItem = {
                                    id,
                                    // position: Object.keys(data[propertyDescription.id]) ? Object.keys(data[propertyDescription.id]).length : 0,
                                    // kind: "object",
                                    items: propertyDescription.items.items.map(item => ({
                                        ...item, value: null
                                    }))
                                }
                            } else if (["string", "text", "number"].indexOf(propertyDescription.items.kind) !== -1){
                                // var newItem = {
                                //     id,
                                //     // position: Object.keys(data[propertyDescription.id]) ? Object.keys(data[propertyDescription.id]).length : 0,
                                //     // kind: propertyDescription.items.kind,
                                //     value: null
                                // }
                                var newItem = ''
                            }

                            targetDataPath.push(newItem)

                            return { ...data }
                        })
                }}>
                    <PlusIcon className="-ml-0.5 mr-1 h-3 w-3" aria-hidden="true" />
                    Add
                </button></div>
            ]

        } else if (propertyDescription.kind === 'object'){
            bodyEl = propertyDescription.items.map((prop, j) => <SingleProperty
                key={prop.title} property={prop} setValue={function(propertyTitle, value){
                    setItem(this.id, null, prop.id, value, this.isBody)
                }.bind(propertyDescription)}
                defaultValue={propertiesToGetDefaultValueFrom && propertiesToGetDefaultValueFrom[propertyName] && propertiesToGetDefaultValueFrom[propertyName][prop.id]}
                focused={focused} onFocus={onFocus} onBlur={onBlur}
            />)

        } else {
            var defaultValue

            if (["string", "text", "number"].indexOf(propertyDescription.kind) !== -1){
                defaultValue = propertiesToGetDefaultValueFrom && propertiesToGetDefaultValueFrom[propertyName] || ''

            } else if (propertyDescription.kind === 'boolean'){
                defaultValue = propertiesToGetDefaultValueFrom && propertiesToGetDefaultValueFrom[key] || false
            }

            bodyEl = <SingleProperty
                property={propertyDescription} setValue={function(propertyTitle, value){
                    setItem(this.id, null, null, value, this.isBody)
                }.bind(propertyDescription)} defaultValue={defaultValue}
                focused={focused} onFocus={onFocus} onBlur={onBlur}
            />
        }

        propertiesBody.push(<div key={propertyName + (propertyDescription.isBody ? '-body' : '')}>
            {['object', 'list'].indexOf(propertyDescription.kind) !== -1 ? <div>{propertyDescription.title}</div> : null}
            {bodyEl}
        </div>)
    })

    return <div>
        {propertiesBody}
    </div>
}


const ListItem = ({ propertyID, propertiesIndex, index, item, kind, setItem, deleteItem, defaultValue, isBody }) => {
    const [focused, setFocused] = useState(false)

    var setValue = (propertyTitle, value) => {
        setItem(propertyID, index, propertyTitle, value, isBody )
    }

    var onFocus = e => setFocused(true),
        onBlur = e => setFocused(false)

    return <div>
        <div className='flex'>
            <div>
                {kind === "object" ? item.items.map((property, i) => <SingleProperty
                    key={property.title} property={property} setValue={setValue}
                    defaultValue={defaultValue?.items && defaultValue.items[i]?.value}
                    focused={focused} onFocus={onFocus} onBlur={onBlur}
                />) : null}
                {["string", "text", "number"].indexOf(kind) !== -1 ? <SingleProperty
                    key={item.title} property={item} setValue={setValue}
                    defaultValue={/*defaultValue?.value*/defaultValue}
                    focused={focused} onFocus={onFocus} onBlur={onBlur}
                /> : null}
            </div>
            <div className='flex items-center'>
                <button
                  onClick={e => deleteItem(propertiesIndex, propertyID, item.id, isBody)}
                  type="button"
                  className="inline-flex items-center rounded-full p-1 text-gray-400 hover:text-gray-600 shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
                >
                    <XMarkIcon className="-ml-0.5 h-6 w-6" aria-hidden="true" />
                </button>
            </div>
        </div>
        {/*<div className='text-xs text-gray-400 my-1'>ID: {item.id}</div>*/}
    </div>
}



function SlideBackgroundPicker({ background, setValue, slideKey }){
    const [data, setData] = useState(background || {})
    const [focused, setFocused] = useState(false)
    const [hasSelectedPreset, setHasSelectedPreset] = useState(false)
    const slideKeyRef = useRef(slideKey)

    useEffect(() => {
        if (data && !data.preset && hasSelectedPreset){
            setHasSelectedPreset(false)

        } else if (data && data.preset && !hasSelectedPreset) {
            setHasSelectedPreset(true)
        }

        if (data && JSON.stringify(background) !== JSON.stringify(data) && !(
            background === undefined && !Object.keys(data).length)){
            setValue(data)
        }
    }, [data])

    useEffect(() => {
        setHasSelectedPreset(false)
        setData(background || {})
        slideKeyRef.current = slideKey
    }, [slideKey])

    var onFocus = e => setFocused(true),
        onBlur = e => setFocused(false)

    var setItem = (property, value) => {
        if (property === 'preset'){
            if (value === 'none'){
                setData({})
            } else {
                setData({ [property]: value })
            }
        } else {
            setData(update(data, { [property]: { $set: value } }))
        }
    }

    return <div>
        <div>Background</div>
        <SingleProperty options={['none'].concat(Object.keys(backgrounds))}
            property={{
                id: 'backgroundPreset', title: 'Preset', kind: 'string'
            }} setValue={function(propertyTitle, value){
                setItem('preset', value)
            }} defaultValue={background?.preset || ''}
            focused={focused} onFocus={onFocus} onBlur={onBlur}
        />
    {!hasSelectedPreset ? [<SingleProperty key='backgroundImage'
            options={['none'].concat(Object.keys(patterns))}
            property={{
                id: 'backgroundImage', title: 'Image', kind: 'string'
            }} setValue={function(propertyTitle, value){
                setItem('image', value)
            }} defaultValue={background?.image || ''}
            focused={focused} onFocus={onFocus} onBlur={onBlur}
        />,
        <SingleProperty key='backgroundColor'
            property={{
                id: 'backgroundColor', title: 'Color', kind: 'string'
            }} setValue={function(propertyTitle, value){
                setItem('color', value)
            }} defaultValue={background?.color || ''}
            focused={focused} onFocus={onFocus} onBlur={onBlur}
        />] : null}
    </div>
}


const SingleProperty = ({ property, options, optionsRenderFn, setValue, defaultValue, onFocus, onBlur, focused }) => {
    const inputRef = useRef()
    const throttleRef = useRef()

    useEffect(() => {
        if (inputRef.current !== document.activeElement && defaultValue !== inputRef.current.value){
            if (['text', 'string', 'number'].indexOf(property.kind) !== -1){
                inputRef.current.value = defaultValue || ''
            } else if (property.kind === 'boolean'){
                inputRef.current.checked = defaultValue === true
            }
        }
    }, [defaultValue])

    var onInputBlur = e => {
        onBlur()
        // setValue(property.id || property.title, e.target.value)
    }

    var onInputChange = e => {
        var value = e.target.value
        if (property.kind === 'number'){
            value = parseInt(e.target.value)
        }

        setValue(property.id || property.title, value)
        // throttleCall(throttleRef, setValue, 2, property.title, e.target.value)
    }

    var body
    if (property.kind === 'text'){
        body = [
            <div key='title'>{property.title}</div>,
            <div key='body'><textarea ref={inputRef}
                onChange={onInputChange} onBlur={onBlur} //onBlur={onInputBlur}
            /></div>
        ]
    } else if (property.kind === 'boolean'){
        body = <div>
            <input key='body' type="checkbox" ref={inputRef}
                onChange={e => setValue(property.id || property.title, e.target.checked)} />
            <label key='title' className="ml-1">{property.title}</label>
        </div>

    } else if (options || property.options){
    // } else if (['fontFamily', 'backgroundPreset', 'backgroundImage', 'easing', 'on'].indexOf(property.id) !== -1){
        body = [
            <div key='title'>{property.title}</div>,
            <select key='select' ref={inputRef} className="py-0.5 px-1 pr-8 border-slate-200 hover:border-slate-400"
                onChange={e => {
                    if (property.onSelect){
                        property.onSelect(e)
                    }
                    onInputChange(e)
                }} onFocus={onFocus} onBlur={onBlur}>
                {(options || property.options).map(
                    optionsRenderFn || (op => <option
                        key={op.value || op.id || op}
                        value={op.value || op.id || op}>{op.title || op}</option>))}
            </select>
        ]

    /*
    } else if (property.id === 'fontFamily'){
        body = [
            <div key='body'>{property.title}</div>,
            <select ref={inputRef} className="py-0.5 px-1 pr-8 border-slate-200 hover:border-slate-400"
                onChange={(e) => {
                    loadFont(e.target.value)
                    onInputChange(e)
                }} onFocus={onFocus} onBlur={onBlur}>
                <option value="Arial">Arial</option>
                {availableGoogleFonts.map(font => <option value={font}>{font}</option>)}
            </select>
        ]


    } else if (property.id === 'backgroundPreset'){
        body = [
            <div key='body'>{property.title}</div>,
            <select ref={inputRef} className="py-0.5 px-1 pr-8 border-slate-200 hover:border-slate-400"
                onChange={onInputChange} onFocus={onFocus} onBlur={onBlur}>
                <option value="none">-</option>
                {Object.keys(backgrounds).map(background => <option value={background}>{background}</option>)}
            </select>
        ]

    } else if (property.id === 'backgroundImage'){
        body = [
            <div key='body'>{property.title}</div>,
            <select ref={inputRef} className="py-0.5 px-1 pr-8 border-slate-200 hover:border-slate-400"
                onChange={onInputChange} onFocus={onFocus} onBlur={onBlur}>
                <option value="none">-</option>
                {Object.keys(patterns).map(pattern => <option value={pattern}>{pattern}</option>)}
            </select>
        ]

    } else if (property.id === 'easing'){
        body = [
            <div key='body'>{property.title}</div>,
            <select ref={inputRef} className="py-0.5 px-1 pr-8 border-slate-200 hover:border-slate-400"
                onChange={onInputChange} onFocus={onFocus} onBlur={onBlur}>
                {availableEasings.map((easing, i) => <option value={i}>{easing.join(', ')}</option>)}
            </select>
        ]

    } else if (property.id === 'on'){
        body = [
            <div key='body'>{property.title}</div>,
            <select ref={inputRef} className="py-0.5 px-1 pr-8 border-slate-200 hover:border-slate-400"
                onChange={onInputChange} onFocus={onFocus} onBlur={onBlur}>
                <option value="click">Click</option>
                <option value="previous">Previous</option>
            </select>
        ]
    */
    } else {
        body = [
            <div key='title'>{property.title}</div>,
            <div key='body'><input type="text" onFocus={onFocus} ref={inputRef}
                onChange={onInputChange} onBlur={onBlur} //onBlur={onInputBlur}
            /></div>,
        ]
    }

    return <div className="mt-2">
        {body}
    </div>
}
