import React from 'react'

const slideBackgrounds = {
    flyingCubes: {
        id: 'flyingCubes',
        fallbackColor: 'blue',
        body: <div className='Background-flyingCubes'>
            <div className='cube' /><div className='cube' />
            <div className='cube' /><div className='cube' />
            <div className='cube' /><div className='cube' />
        </div>,

        style: `

        @keyframes flyingcube {
          from {
            transform: scale(0) rotate(0deg) translate(-50%, -50%);
            opacity: 1;
          }
          to {
            transform: scale(20) rotate(960deg) translate(-50%, -50%);
            opacity: 0;
          }
        }

        .Background-flyingCubes {
            background-color: #0040C1;
            position: relative;
            height: 100%;
            overflow: hidden;
        }

    .Background-flyingCubes .cube {
      position: absolute;
      top: 80vh;
      left: 45vw;
      width: 10px;
      height: 10px;
      border: solid 1px #003298;
      transform-origin: top left;
      transform: scale(0) rotate(0deg) translate(-50%, -50%);
      animation: flyingcube 12s ease-in forwards infinite;
  }

      .Background-flyingCubes .cube:nth-child(2n) {
        border-color: #0051f4;
      }

      .Background-flyingCubes .cube:nth-child(2) {
        animation-delay: 2s;
        left: 25%;
        top: 40%;
      }

      .Background-flyingCubes .cube:nth-child(3) {
        animation-delay: 4s;
        left: 75%;
        top: 50%;
      }

      .Background-flyingCubes .cube:nth-child(4) {
        animation-delay: 6s;
        left: 90%;
        top: 10%;
      }

      .Background-flyingCubes .cube:nth-child(5) {
        animation-delay: 8s;
        left: 10%;
        top: 85%;
      }

      .Background-flyingCubes .cube:nth-child(6) {
        animation-delay: 10s;
        left: 50%;
        top: 10%;
      }
    }

        `
    }
}


export default slideBackgrounds
