import { Link, useParams, Outlet, useOutlet, useNavigate, useLocation, useOutletContext } from "react-router-dom"
import {useContext, useEffect, useState} from 'react'
import {classNames} from '../utils'
import {UserFilter} from './import-export'
import {UserContext} from '../App'
import {FirebaseContext} from '../index'
import api from '../api'


export default function Tasks(){
    var outlet = useOutlet()
    let location = useLocation()
    let navigate = useNavigate()
    const [user] = useContext(UserContext)
    const [app, db] = useContext(FirebaseContext)

    const [users, setUsers] = useState()
    const usersAndAll = [{ id: 0, firstName: 'All', lastName: '' }].concat(users || [])
    var isAdmin = user.email === 'varun@opencurriculum.org'

    const [selectedPerson, setSelectedPerson] = useState(isAdmin ? usersAndAll[0] : user)

    useEffect(() => {
        if (!outlet){
            navigate('assigned', { replace: true })
        }
    }, [location])

    var subpage = location.pathname.split('/')[3]

    const tabs = [
      { name: 'Assigned', href: 'assigned', current: subpage === 'assigned' },
      { name: 'Submitted', href: 'submitted', current: subpage === 'submitted' },
      { name: 'Reviewed', href: 'reviewed', current: subpage === 'reviewed' },
      { name: 'Approved', href: 'approved', current: subpage === 'approved' }
    ]

    const currentTab = tabs.find((tab) => tab.current)

    useEffect(() => {
        if (isAdmin){
            api.getUsers(db).then(users => setUsers(users))
        }
    }, [])

    return <div>
        {isAdmin ? <div className="mx-auto max-w-7xl py-4 px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
                <div className="mb-4 sm:flex-auto">
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    <button
                      type="button"
                      className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                    >
                      Add task
                    </button>
                    <UserFilter users={users} selectedPerson={selectedPerson} />
                </div>
            </div>
        </div> : null}

        <header className="pt-2">
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">

            <div className="relative border-b border-gray-200 pb-5 sm:pb-0">
              <div className="mt-3 sm:mt-4">
                <div className="sm:hidden">
                  <label htmlFor="current-tab" className="sr-only">
                    Select a tab
                  </label>
                  <select
                    id="current-tab"
                    name="current-tab"
                    className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    defaultValue={currentTab ? currentTab.name : null}
                  >
                    {tabs.map((tab) => (
                      <option key={tab.name}>{tab.name}</option>
                    ))}
                  </select>
                </div>
                <div className="hidden sm:block">
                  <nav className="-mb-px flex space-x-8">
                    {tabs.map((tab) => (
                      <Link
                        key={tab.name}
                        to={tab.href}
                        className={classNames(
                          tab.current
                            ? 'border-indigo-500 text-indigo-600'
                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                          'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm'
                        )}
                        aria-current={tab.current ? 'page' : undefined}
                      >
                        {tab.name}
                      </Link>
                    ))}
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </header>

        <main>
          <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
          <div className="rounded-b-lg bg-white px-5 py-6 shadow sm:px-6">
            <Outlet context={[selectedPerson]} />
            </div>
          </div>
        </main>

    </div>
}


export const Assigned = () => {
    const [tasks, setTasks] = useState()
    const [selectedPerson] = useOutletContext()
    const [app, db] = useContext(FirebaseContext)

    useEffect(() => {
        api.getTasks(db, selectedPerson.id !== 0 && selectedPerson.email, 'assigned').then(tasks => {
            setTasks(tasks)
        })
    }, [])

    return <div><TaskList tasks={tasks} /></div>
}


export const Submitted = () => {
    const [tasks, setTasks] = useState()
    const [selectedPerson] = useOutletContext()
    const [app, db] = useContext(FirebaseContext)

    useEffect(() => {
        api.getTasks(db, selectedPerson.id !== 0 && selectedPerson.email, 'review').then(tasks => {
            setTasks(tasks)
        })
    }, [])

    return <div><TaskList tasks={tasks} /></div>
}


export const Reviewed = () => {
    const [tasks, setTasks] = useState()
    const [selectedPerson] = useOutletContext()
    const [app, db] = useContext(FirebaseContext)

    useEffect(() => {
        api.getTasks(db, selectedPerson.id !== 0 && selectedPerson.email, 'reviewed').then(tasks => {
            setTasks(tasks)
        })
    }, [])

    return <div><TaskList tasks={tasks} /></div>
}


export const Approved = () => {
    const [tasks, setTasks] = useState()
    const [selectedPerson] = useOutletContext()
    const [app, db] = useContext(FirebaseContext)

    useEffect(() => {
        api.getTasks(db, selectedPerson.id !== 0 && selectedPerson.email, 'complete').then(tasks => {
            setTasks(tasks)
        })
    }, [])

    return <div><TaskList tasks={tasks} /></div>
}


var TaskList = ({ tasks }) => {
    return <div className="flex flex-col">
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                    Title
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    For
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Due date
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">View</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {tasks && tasks.map((task) => (
                  <tr key={task.id}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      {task.title}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{task.parentTitle || 'Unknown'}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{task.due ? <time dateTime={task.due.toDate()}>{task.due.toDate().toLocaleDateString("en-US", { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</time> : null}</td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                      <Link to={`/app/task/${task.id}`} className="text-indigo-600 hover:text-indigo-900">
                        View<span className="sr-only">, {task.title}</span>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
}
