import { easings } from '@react-spring/web'


var animationPresets = {
    growIn: {
        easing: easings.easeInOutBack,
        duration: 800,
        from: { transform: 'scale(0)', opacity: 0, top: '0px' },
        to: { transform: 'scale(1)', opacity: 1 },
        delay: i => i * 200
    },

    slideOut: {
        easing: easings.easeOutExpo,
        duration: 200,
        from: { top: '0px', opacity: 1 },
        to: { top: '15px', opacity: 0 },
        delay: (i, textLength) => ((textLength - 1) - i) * 60
    },

    slideInLeftAsWords: {
        easing: easings.easeOutExpo,
        duration: 200,
        from: { marginLeft: '-15px', opacity: 0 },
        to: { marginLeft: '0px', opacity: 1 },
        delay: (i, textLength, duration) => i * (duration / textLength)
    },

    slideInLeft: {
        easing: easings.easeOutExpo,
        duration: 200,
        from: { marginLeft: '-15px', opacity: 0 },
        to: { marginLeft: '0px', opacity: 1 },
    },

    slideInTop: {
        easing: easings.easeOutExpo,
        duration: 200,
        from: { marginTop: '-15px', opacity: 0 },
        to: { marginTop: '0px', opacity: 1 },
    },

    slideInBottom: {
        easing: easings.easeOutExpo,
        duration: 200,
        from: { marginTop: '0px', opacity: 0 },
        to: { marginTop: '-15px', opacity: 1 },
    },

    fadeOut: {
        easing: easings.easeOutCubic,
        duration: 150,
        from: { opacity: 1 },
        to: { opacity: 0 },
        delay: i => i * 80
    },

    appear: {
        easing: easings.easeOutCubic,
        duration: 0,
        from: { opacity: 0 },
        to: { opacity: 1 },
        delay: i => i * 120
    },

    fadeIn: {
        easing: easings.easeOutCubic,
        duration: 150,
        from: { opacity: 0 },
        to: { opacity: 1 },
        delay: i => i * 80
    },

    shrinkIn: {
        easing: easings.easeInOutBack,
        duration: 800,
        from: {
            transform: 'scale(3)', opacity: 0, top: '0px',
            textShadow: '0 0 10px rgba(0,0,0,0.5)', color: 'transparent'
        },
        to: { transform: 'scale(1)', opacity: 1, textShadow: 'none', color: 'unset' },
        delay: (i, textLength, duration) => i * (duration / textLength)
    },
}


export default animationPresets
