import {useContext, useEffect} from 'react'
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import {auth as firebaseuiauth} from 'firebaseui'
import {FirebaseContext} from '../index'
import '../firebaseui.css';
import logo from '../logo.svg';
import { useLocation, useNavigate } from "react-router-dom"
import { UserContext } from '../App'
import api from '../api'
import {Dropbox} from 'dropbox'


var initializeButton = () => {
    // This will fail if we are being redirected for one-time login.
    global.hubfirebaseui.start('#firebaseui-auth-container', {
      signInOptions: [
        // List of OAuth providers supported.
        GoogleAuthProvider.PROVIDER_ID
      ],
      callbacks: {
        signInSuccessWithAuthResult: function(authResult, redirectUrl) {
            return false
        }
      },
    })
}


export default function Login() {
    const [app, db] = useContext(FirebaseContext)
    let location = useLocation()

    useEffect(() => {
        var auth = getAuth(app);

        var searchParams = new URLSearchParams(location.search)
        if (!searchParams.has('oneTimeEmailLogin')){
            if (!global.hubfirebaseui){
                // Initialize the FirebaseUI Widget using Firebase.
                global.hubfirebaseui = new firebaseuiauth.AuthUI(auth)
                initializeButton()

            } else if (!global.hubfirebaseui.isPendingRedirect()){
                initializeButton()
            }
        }

    }, [])

      return (
        <>
          <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            <div className="w-full max-w-md space-y-8">
              <div>
                <img
                  className="mx-auto h-12 w-auto"
                  src={logo}
                />
                <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
                  Sign in to your account
                </h2>
              </div>
              <div className="mt-8 space-y-6">
                <div id='firebaseui-auth-container'></div>
              </div>

            </div>
          </div>
        </>
      )
}


export const DROPBOX_CLIENT_ID = '5dnvgiedocakz1f'


export const DropboxRedirect = () => {
    let location = useLocation()
    const [app, db] = useContext(FirebaseContext)
    const [user, setUser] = useContext(UserContext);
    let navigate = useNavigate()

    useEffect(() => {
        if (user){
            var searchParams = new URLSearchParams(window.location.search)

            var dbx = new Dropbox({ clientId: DROPBOX_CLIENT_ID })

            dbx.auth.setCodeVerifier(window.sessionStorage.getItem('codeVerifier'))
            dbx.auth.getAccessTokenFromCode(
                window.location.origin + window.location.pathname, searchParams.get('code')).then((token) => {
                api.setUserDropboxToken(db, user.email, token.result).then(() => {
                    // I could hypothically setUser dropboxAccessToken here, instead of a hard redirect.
                    setUser({ ...user, dropboxToken: token.result })
                    // navigate(new URL(searchParams.get('state')).pathname)
                    navigate(new URL(searchParams.get('state')).pathname)
                })

            })
        }
    }, [user])

    return null
}
